import axios from "axios";
import {WooSite} from "@/api/model/WooSite";
import {WooOrder, WooOrderFactory, WooOrderJson} from "@/api/model/WooOrder";
import PageOptions from "@/api/model/PageOptions";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import {Resource} from "@/api/model/Resource";



export default class WooOrderService {

  public static async list(site: WooSite, pageOptions: PageOptions): Promise<PaginatedResponse<WooOrder>> {
    return axios.get<PaginatedResponseJson<WooOrderJson>>(`/api/sites/${site.id}/orders?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooOrderFactory));
  }

  public async listBySiteId(siteId: number | string, pageOptions: PageOptions): Promise<PaginatedResponse<WooOrder>> {
    return axios.get<PaginatedResponseJson<WooOrderJson>>(`/api/sites/${siteId}/orders?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooOrderFactory));
  }

  public static async get(id: number): Promise<WooOrder> {
    return axios.get<Resource<WooOrderJson>>(`/api/orders/${id}`)
      .then((resp) => WooOrder.fromJSON(resp.data.data));
  }

}
