import {WooProduct, WooProductJson} from "@/api/model/WooProduct";

export interface WooOrderItemJson {
  id: number;
  created_at: string | null;
  updated_at: string | null;
  product: WooProductJson | null;

  woo_item_id: number;
  name: string;
  product_id: 	number;
  variation_id: 	number;
  quantity: 	number;
  tax_class: 	string;
  subtotal: 	number;
  subtotal_tax: 	number;
  total: 	number;
  total_tax: 	number;
  sku: 	string;
  price: 	number;
  woo_order_id: 	number;
  woo_product_id: 	number;
  cog_item_cost: 	number;
  cog_item_total_cost: 	number;

}

export class WooOrderItem {
  id = '';
  product: WooProduct | null = null;
  woo_item_id = 0;
  name = 'Unknown Product';
  product_id = 0;
  variation_id = 0;
  quantity = 1;
  tax_class = '';
  subtotal = 0;
  subtotal_tax = 0;
  total = 0;
  total_tax = 0;
  sku = '';
  price = '';
  woo_order_id = 0;
  woo_product_id = 0;
  cog_item_cost = 0;
  cog_item_total_cost = 0;

  get discount(): number {
    if (this.total >= this.subtotal) {
      return 0;
    }
    return this.subtotal - this.total;
  }

    static fromJSON(json: WooOrderItemJson): WooOrderItem {
        //console.debug("WooOrderItem.fromJSON()", json);
        const response = Object.create(WooOrderItem.prototype);
        return Object.assign(response, json, {
            created_at: json.created_at ? new Date(json.created_at) : null,
            updated_at: json.updated_at ? new Date(json.updated_at) : null,
            product: json.product ? WooProduct.fromJSON(json.product) : null,
        });
    }
}
