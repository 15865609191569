import {DateTime} from "luxon";
import {EmailSequenceEmail, EmailSequenceEmailJson} from "@/api/model/EmailSequence";

type EmailableType = 'App\\Models\\EmailSequenceEmail' | 'App\\Models\\CampaignShot';

export interface WooCustomerEmailJson {
  sent: boolean;
  created_at: string;
  opened: boolean;
  bounced: boolean;
  clicked: boolean;
  unsubscribed: boolean;
  emailable_type: EmailableType;
  emailable: EmailSequenceEmailJson | Record<string, unknown>;
}

class CampaignShot {
  subject = '';
  body = '';
  static fromJSON(json: Record<string, unknown>) {
    const response = Object.create(WooCustomerEmail.prototype);
    return Object.assign(response, json, {
    });
  }
}

export class WooCustomerEmail {
  sent = true;
  created_at = DateTime.now();
  opened = false;
  bounced = false;
  clicked = false;
  unsubscribed = false;
  emailable_type: EmailableType = 'App\\Models\\EmailSequenceEmail';
  emailable: EmailSequenceEmail | CampaignShot | null = null;
  subject = '';

  static fromJSON(json: WooCustomerEmailJson): WooCustomerEmail {
    //console.debug("WooCustomerEmail.fromJSON()", json);
    const response = Object.create(WooCustomerEmail.prototype);
    return Object.assign(response, json, {
      sent: true,
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.now(),
      emailable: json.emailable_type === 'App\\Models\\EmailSequenceEmail'
        ? EmailSequenceEmail.fromJSON(json.emailable as EmailSequenceEmailJson)
        : CampaignShot.fromJSON(json.emailable as Record<string, unknown>),
    });
  }
}

