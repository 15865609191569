import axios from "axios";
import {Resource} from "@/api/model/Resource";
import PricePlan, {PricePlanJson} from "@/api/model/PricePlan";

export default class PricePlanService {

  public async planForOrganisation(organisationId: number | string): Promise<PricePlan> {
    return axios.get<Resource<PricePlanJson>>(`/api/organisations/${organisationId}/plan`)
      .then((resp) => PricePlan.fromJSON(resp.data.data));
  }

}
