













import Vue, {PropOptions} from "vue";

export default Vue.extend({
  name: 'BaseBackground',
  props: {
    image: {
      type: String,
      description: 'The url of the image to show',
    } as PropOptions<string>,
    innerClass: {
      type: String,
      description: 'The class of the inner div. Usually a background color with opacity class',
    } as PropOptions<string>,
    imageTop: {
      type: Boolean,
      default: false,
      description: 'Align the image background to the top',
    } as PropOptions<boolean>,
    imageBottom: {
      type: Boolean,
      default: false,
      description: 'Align the image background to the bottom',
    } as PropOptions<boolean>,
    imageFixed: {
      type: Boolean,
      default: false,
      description: 'Set the background to fixed for a parallax effect',
    } as PropOptions<boolean>,
  },
  computed: {
    classContainer () {
      return {
        'bg-image-top': this.imageTop,
        'bg-image-bottom': this.imageBottom,
        'bg-image-fixed': this.imageFixed,
      };
    },
    classContainerInner () {
      return {
        [this.innerClass]: this.innerClass,
      };
    },
  },
});
