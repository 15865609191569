import {DateTime} from "luxon";
import Domain, {DomainJson} from "@/api/model/Domain";

export interface OrganisationJson {
  created_at: string;
  global_currency: string;
  has_sites: boolean;
  id: number;
  name: string;
  owner_id: number;
  updated_at: string;
  domains: DomainJson[];
  billing_country: string | null;
  coupon: string | null;
  free_account: boolean;
}



export default class Organisation {
  created_at: DateTime|null = null;
  updated_at: DateTime|null = null;
  id = 0;

  global_currency = 'USD';
  has_sites = true;
  name = '';
  owner_id: number|null = null;
  domains: Domain[] = [];
  billing_country: string|null = null;
  coupon: string|null = null;
  free_account = false;

  toJSON(): OrganisationJson {
    return Object.assign({} as OrganisationJson, this, {
    });
  }

  static fromJSON(json: OrganisationJson): Organisation {
    //console.debug("Organisation.fromJSON", json);
    const organisation = Object.create(Organisation.prototype);
    return Object.assign(organisation, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
      domains: json.domains ? json.domains.map(json => Domain.fromJSON(json)) : [],
    });
  }
}
