type VerificationStatus = "pending" | "verified" | "unverified" | "unavailable";

interface VerificationJson {
    status: VerificationStatus;
    verified_address: string;
    verified_name: string;
}

export class Verification {
  status: VerificationStatus = "pending";
  verified_address = 'unknown';
  verified_name = 'unknown';

  static fromJSON(json: VerificationJson): Verification {
    //console.debug("Verification.fromJSON", json);
    const response = Object.create(Verification.prototype);
    return Object.assign(response, json, {

    });
  }
}

export type TaxIdType =
  "eu_vat"
  | "br_cnpj"
  | "br_cpf"
  | "gb_vat"
  | "nz_gst"
  | "au_abn"
  | "au_arn"
  | "in_gst"
  | "no_vat"
  | "za_vat"
  | "ch_vat"
  | "mx_rfc"
  | "sg_uen"
  | "ru_inn"
  | "ru_kpp"
  | "ca_bn"
  | "hk_br"
  | "es_cif"
  | "tw_vat"
  | "th_vat"
  | "jp_cn"
  | "jp_rn"
  | "li_uid"
  | "my_itn"
  | "us_ein"
  | "kr_brn"
  | "ca_qst"
  | "ca_gst_hst"
  | "ca_pst_bc"
  | "ca_pst_mb"
  | "ca_pst_sk"
  | "my_sst"
  | "sg_gst"
  | "ae_trn"
  | "cl_tin"
  | "sa_vat"
  | "id_npwp"
  | "my_frp"
  | "il_vat"
  | "unknown";



export interface TaxIdJson {
    id: string;
    object: "tax_id";
    country: string;
    created: number;
    customer: string;
    livemode: boolean;
    type: TaxIdType;
    value: string;
    verification: VerificationJson;
}

export default class TaxId {
  id = 'unknown';
  value = 'unknown';
  type: TaxIdType | null = null;
  verification = new Verification();


  constructor(value: string, type: TaxIdType | null) {
    this.value = value;
    this.type = type;
  }

  static fromJSON(json: TaxIdJson): TaxId {
    //console.debug("TaxId.fromJSON", json);
    const response = Object.create(TaxId.prototype);
    return Object.assign(response, json, {
       verification: json.verification ? Verification.fromJSON(json.verification) : new Verification(),
    });
  }
}
