import {DateTime} from "luxon";
import {v4 as uuidv4} from 'uuid';

export interface DashboardJson {
  id: number;
  created_at: string;
  updated_at: string;
  uuid: string;
  name: string;
  layout: string;
  user_id: number;
  shared: boolean;
  default: boolean;
  readonly: boolean;
}

export default class Dashboard {
  id = 0;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  uuid: string = uuidv4();
  name = 'Dashboard';
  layout = '[]';
  user_id = 0;
  shared = false;
  default = false;
  readonly = false;

  static fromJSON(json: DashboardJson): Dashboard {
    //console.debug("Dashboard.fromJSON", json);
    const response = Object.create(Dashboard.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? new Date(json.created_at) : null,
      updated_at: json.updated_at ? new Date(json.updated_at) : null,
    });
  }
}
