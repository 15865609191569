import {DateTime} from "luxon";
import {v4 as uuidv4} from "uuid";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";
import {WooProduct, WooProductJson} from "@/api/model/WooProduct";
import {FromJsonFactory} from "@/api/model/PaginatedResponse";
import {DelayPeriod, EmailTrigger} from "@/api/EmailSequenceService";


const calculatePeriod = function(delay: number): DelayPeriod {
  if (delay % 720 === 0) return { value: 4, text: 'Months', multiplier: 720};
  else if (delay % 168 === 0) return { value: 3, text: 'Weeks', multiplier: 168};
  else if (delay % 24 === 0) return { value: 2, text: 'Days', multiplier: 24};
  else return { value: 1, text: 'Hours', multiplier: 1};
};

const calculateDelay = function(delay: number): number {
  if (delay === 0) return 0;
  else if (delay % 720 === 0) return  delay / 720;
  else if (delay % 168 === 0) return delay / 168;
  else if (delay % 24 === 0) return delay / 24;
  else return delay;
};


export interface Stats {
  id: number;
  sent:	number;
  unsubscribed:	number;
  opened: number;
  bounced: number;
  clicked: number;
}

export interface EmailSequenceJson {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    email_trigger_id: number;
    uuid: string;
    emails: EmailSequenceEmailJson[];
    site: WooSiteJson;
    products: WooProductJson[];
    live: boolean;
    customer_segment_id: number;
}

export class EmailSequence {
    id = 0;
    created_at = DateTime.now();
    updated_at = DateTime.now();
    name = '';
    trigger: EmailTrigger = EmailTrigger.byId(3).get();
    email_trigger_id = 3;
    emails: EmailSequenceEmail[] = [];
    uuid = uuidv4();
    site: WooSite = new WooSite();
    products: WooProduct[] = [];
    live = false;
    customer_segment_id = 0;
  stats: Stats = {
    id: 0,
    sent:	0,
    unsubscribed:	0,
    opened: 0,
    bounced: 0,
    clicked: 0,
  };

    static fromJSON(json: EmailSequenceJson): EmailSequence {
        //console.debug("EmailSequence.fromJSON", json);
        const response = Object.create(EmailSequence.prototype);
        return Object.assign(response, json, {
            created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
            updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
            trigger: EmailTrigger.byId(json.email_trigger_id).get(),
            emails: json.emails ? json.emails.map((email, index) => EmailSequenceEmail.fromJSONWithDelay(email, index, json.emails)) : [],
            site: WooSite.fromJSON(json.site),
            products: json.products ? json.products.map(product => WooProduct.fromJSON(product)) : [],
        });
    }
}

export interface EmailSequenceEmailJson {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    layout: string;
    subject: string;
    body: string;
    delay: number;
    uuid: string;
    trigger: string;
    coupon_settings: string;
}

export class EmailSequenceEmail {
    constructor(name?: string, subject?: string) {
        this.name = name ?? '';
        this.subject = subject ?? '';
    }

    id = 0;
    created_at = DateTime.now();
    updated_at = DateTime.now();
    name = '';
    layout = 'standard';
    subject = '';
    body = '';
    delay = 0;
    delayToShow = 0;
    emailTotalDelay = '';
    period: DelayPeriod = {value: 0, text: '', multiplier: 0};
    uuid = uuidv4();
    coupon_settings = "{}";

    static fromJSONWithDelay(json: EmailSequenceEmailJson, index: number, emails: EmailSequenceEmailJson[]): EmailSequenceEmail {
        //console.debug("EmailSequence.fromJSON", json);
        const response = Object.create(EmailSequenceEmail.prototype);
        const delay = (index > 0) ? (json.delay - emails[index - 1].delay) : json.delay;
        return Object.assign(response, json, {
            id: index,
            created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
            updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
            delayToShow: calculateDelay(delay),
            period: calculatePeriod(delay),
        });
    }

  static fromJSON(json: EmailSequenceEmailJson): EmailSequenceEmail {
    //console.debug("EmailSequence.fromJSON", json);
    const response = Object.create(EmailSequenceEmail.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
    });
  }
}

export class EmailSequenceFactory implements FromJsonFactory<EmailSequence, EmailSequenceJson> {
    fromJSON(json: EmailSequenceJson): EmailSequence {
        return EmailSequence.fromJSON(json);
    }
}
