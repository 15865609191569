import axios, {AxiosResponse} from "axios";
import {WooSite} from "@/api/model/WooSite";
import PageOptions from "@/api/model/PageOptions";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import {Resource} from "@/api/model/Resource";
import {WooProduct, WooProductFactory, WooProductJson} from "@/api/model/WooProduct";
import {ResourceCollection} from "@/api/model/ResourceCollection";

export default class WooProductService {

  public static async listBySiteId(siteId: number | string, pageOptions: PageOptions): Promise<PaginatedResponse<WooProduct>> {
    return axios.get<PaginatedResponseJson<WooProductJson>>(`/api/sites/${siteId}/products?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooProductFactory));
  }

  public async getAll(site: WooSite): Promise<WooProduct[]> {
    if (site.id === "all" || site.id === null) {
      throw "exception";
    }
    return this.getAllBySiteId(site.id);
  }

  public async getAllBySiteId(siteId: number): Promise<WooProduct[]> {
    return axios.get<ResourceCollection<WooProductJson>>(`/api/sites/${siteId}/products`)
      .then((resp) => resp.data.data.map(json => WooProduct.fromJSON(json)));
  }

  public static async get(id: number): Promise<WooProduct> {
    return axios.get<Resource<WooProductJson>>(`/api/products/${id}`)
      .then((resp) => WooProduct.fromJSON(resp.data.data));
  }

  public static async update(product: WooProduct): Promise<WooProduct> {
    return axios.put<Resource<WooProductJson>>(`/api/products/${product.id}`, product)
      .then((resp) => WooProduct.fromJSON(resp.data.data));
  }

  public static delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/products/${id}`);
  }

  public async setDefaultImageById(productId: number, imageId: number) : Promise<WooProduct> {
     return axios.put<Resource<WooProductJson>>(`/api/products/${productId}/default-image`, {image_id: imageId})
         .then((resp) => WooProduct.fromJSON(resp.data.data));
  }
}
