




























































// SimpleBar, for more info and examples you can check out https://github.com/Grsmto/simplebar/tree/master/packages/simplebar-vue
import simplebar from 'simplebar-vue';

// Get navigation data
import menuList from '@/data/menu';
import Vue from "vue";
import Dashboard from "@/api/model/Dashboard";
import {mapActions, mapGetters} from "vuex";
import {WooSite} from "@/api/model/WooSite";
import {Location} from "vue-router";

interface MenuItem {
  name: string;
  icon?: string;
  heading?: boolean;
  to?: Location | string;
  sub?: MenuItem[];
}

export default Vue.extend({
  name: 'BaseSidebar',
  props: {
    classes: String,
  },
  components: {
    simplebar,
  },
  data () {
    return {
      // Get main navigation
      navigation: menuList.main as MenuItem[],
    };
  },
  computed: {
    ...mapGetters([
      'currentSite',
    ]),
    ...mapGetters('dashboard', [
      'dashboards',
    ]),
    menu(): MenuItem[] {
        const dashboard = {
        name: 'Dashboard',
        to: '/dashboard/default',
        icon: 'si si-speedometer',
        subActivePaths: '/dashboard',
        sub: this.dashboards.map((dashboard: Dashboard) => ({
          name: dashboard.name,
          to: {
            name: 'Dashboard',
            params: { id: dashboard.uuid },
          },
          icon: 'far fa-circle',
        })),
      };

      return [
        dashboard,
        ...this.navigation,
        ];
    },
    open(): boolean {
      return this.$store.state.layout.sidebar && this.$store.state.settings.sidebarMini;
    },
  },
  watch: {
    currentSite(site: WooSite): void {
      this.updateSiteId(this.navigation, site.id);
    },
  },
  methods: {
    ...mapActions('dashboard', [
      'fetchDashboards',
    ]),
    updateSiteId(menu: MenuItem[], siteId: number | "all" | null): void {
      for (const item of menu) {
        if (typeof item?.to === 'object' && item.to.params && (item?.to?.params?.site_id ?? null) !== null) {
          item.to.params.site_id = "" + siteId;
        }
        if (item.sub) {
          this.updateSiteId(item.sub, siteId);
        }
      }
    },
    sidebarOpen (): void {
      this.open = !this.open;
    },
  },
  created() {
    this.fetchDashboards();
  },

});
