import axios, {AxiosResponse} from "axios";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import Dashboard, {DashboardJson} from "@/api/model/Dashboard";
import {Resource} from "@/api/model/Resource";

export default class DashboardService {
  public async list(): Promise<Dashboard[]> {
    return axios.get<ResourceCollection<DashboardJson>>(`/api/dashboards`)
      .then((resp) => resp.data.data.map(json => Dashboard.fromJSON(json)));
  }

  public async createOrUpdate(dashboard: Dashboard): Promise<Dashboard> {
    if (dashboard.id) {
      return this.update(dashboard);
    }
    return this.create(dashboard);
  }

  public async create(dashboard: Dashboard): Promise<Dashboard> {
    return axios.post<Resource<DashboardJson>>(`/api/dashboards/`, dashboard)
      .then((resp) => Dashboard.fromJSON(resp.data.data));
  }

  public async update(dashboard: Dashboard): Promise<Dashboard> {
    return axios.put<Resource<DashboardJson>>(`/api/dashboards/${dashboard.id}`, dashboard)
      .then((resp) => Dashboard.fromJSON(resp.data.data));
  }
  public async delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/dashboards/${id}`);
  }
}
