// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Array<T> {
  sum(): number;
  groupBy<K extends string | number | symbol>(keyFn: (input: T) => K): Record<K, T[]>;
}

Array.prototype.sum = function(): number {
  const sum = (total: number, num: number): number => +total + +num;
  return this.reduce(sum, 0);
};

Array.prototype.groupBy = function<K extends string | number | symbol, T>(keyFn: (input: T) => K) : Record<K, T[]> {
  const groupBy = () => (retVal: Record<K,T[]>, item: T) => {
    const key: K = keyFn(item);
    retVal[key] = [...retVal[key] || [], item];
    return retVal;
  };

  return this.reduce(groupBy(), {});
};
