<template>
    <div class="bg-body-light">
      <div class="content content-full py-3">
        <slot>
          <b-row>
          <b-col class="my-0" v-if="title || $slots.extra">
            <b-row class="d-flex justify-content-start d-flex align-items-end ml-1">
                <h1 class="h3 my-0 mr-2" v-if="title">
                  {{ title }}
                </h1>
              <small class="d-block d-sm-inline-block mt-sm-0 font-w400 text-muted font-size-sm" v-if="subtitle">{{ subtitle }}</small>
            </b-row>
          </b-col>
          <b-col>
            <b-row  class="d-flex justify-content-start d-flex align-items-end mr-1" align-h="end">
              <div class="flex-sm-00-auto ml-sm-3" v-if="$slots.extra">
                <slot name="extra"></slot>
              </div>
            </b-row>
          </b-col>
          </b-row>
        </slot>
      </div>
    </div>
</template>

<script>
export default {
  name: 'BasePageHeading',
  components: {

  },

  props: {
    title: {
      type: String,
      description: 'The title of page heading section',
    },
    subtitle: {
      type: String,
      description: 'The subtitle of page heading section',
    },
  },
};
</script>
