import axios from "axios";
import {Cart, CartFactory, CartJson} from "@/api/model/Cart";
import PageOptions from "@/api/model/PageOptions";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import {Resource} from "@/api/model/Resource";

export default class CartService {

  public async list(siteId: number | string, pageOptions: PageOptions): Promise<PaginatedResponse<Cart>> {
    return axios.get<PaginatedResponseJson<CartJson>>(`/api/sites/${siteId}/carts?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new CartFactory));
  }

  public async get(id: number): Promise<Cart> {
    return axios.get<Resource<CartJson>>(`/api/carts/${id}`)
      .then((resp) => Cart.fromJSON(resp.data.data));
  }

}
