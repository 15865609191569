import {DateTime} from "luxon";
import {AddressJson} from "@/api/model/stripe/Address";
import {Currency} from "@/api/model/stripe/Currency";
import {ShippingJson} from "@/api/model/stripe/Shipping";
import {CustomFieldJson} from "@/api/model/stripe/CustomField";
import {ListJson} from "@/api/model/stripe/List";
import {TaxIdType} from "@/api/model/stripe/TaxId";

type BillingReason = "subscription_cycle"
  | "subscription_create"
  | "subscription_update"
  | "subscription"
  | "subscription_threshold"
  | "upcoming"
  | "manual";


interface AutomaticTaxJson {
  enabled: boolean;
  status: "requires_location_inputs" | "complete" | "failed" | null;
}

interface CustomerTaxIdJson  {
  type: TaxIdType;
  value: string;
}

interface PeriodJson {
  end: number;
  start: number;
}

interface PriceJson {
  id:"price_1JQ8vPBsTJP9KWv8kFwaqMoW";
  object:"price";
  active:false;
  billing_scheme:"per_unit";
  created:1629370603;
  currency:"eur";
  livemode:false;
  lookup_key:null;
  metadata:[];
  nickname:null;
  product:"prod_K4HUT6sABQr9fd";
  recurring:null;
  tax_behavior:"unspecified";
  tiers_mode:null;
  transform_quantity:null;
  type:"one_time";
  unit_amount:1000;
  unit_amount_decimal:"1000"
}

interface LineItemJson {
  id:"il_1JQ8vPBsTJP9KWv81LYzdVUQ";
  object:"line_item";
  amount:1000;
  currency:"eur";
  description:"Subscription";
  discount_amounts:[];
  discountable:true;
  discounts:[];
  invoice_item:"ii_1JQ8vPBsTJP9KWv8lQqhWEzo";
  livemode:false;
  metadata:[];
  period: PeriodJson;
  plan:null;
  price: PriceJson;
  proration:false;
  quantity:1;
  subscription:null;
  tax_amounts:[];
  tax_rates:[];
  type:"invoiceitem"
}

interface PaymentSettingsJson  {
  payment_method_options:null;
  payment_method_types:null;
}

type Status = "draft" | "open" | "paid" | "uncollectible" | "void";

interface StatusTransitionsJson {
  finalized_at: number;
  marked_uncollectible_at:null;
  paid_at: number;
  voided_at:null;
}

interface DefaultTaxRateJson {
  id: string;
  object: "tax_tate";
  active: boolean;
  country: string;
  created: number;
  description: string;
  display_name: string;
  inclusive: boolean;
  jurisdiction: string;
  livemode: boolean;
  metadata: Record<string, unknown>;
  percentage: number;
  state: string;
  tax_type: "vat" | "sales_tax" | string;
}

export interface InvoiceJson{
  id: string;
  object: "invoice";
  account_country: string;
  account_name: string;
  account_tax_ids: string[]|null;
  amount_due: number;
  amount_paid:number;
  amount_remaining:number;
  application_fee_amount: number |null;
  attempt_count: number;
  attempted:boolean;
  auto_advance:boolean;
  automatic_tax: AutomaticTaxJson;
  billing_reason: BillingReason ;
  charge: string;
  collection_method: "charge_automatically" | "send_invoice";
  created:number;
  currency: Currency;
  custom_fields: CustomFieldJson[] | null;
  customer:string;
  customer_address: AddressJson;
  customer_email: string;
  customer_name: string;
  customer_phone: string;
  customer_shipping: ShippingJson;
  customer_tax_exempt: string;
  customer_tax_ids: CustomerTaxIdJson[];
  default_payment_method: string | null;
  default_source: string | null;
  default_tax_rates: DefaultTaxRateJson[];
  description: string;
  discount:null;
  discounts:[];
  due_date:number;
  ending_balance:number;
  footer:null;
  hosted_invoice_url: string | null;
  invoice_pdf:string;
  last_finalization_error:null;
  lines: ListJson<LineItemJson>;
  livemode:boolean;
  metadata:[];
  next_payment_attempt:null;
  number: string;
  on_behalf_of:null;
  paid: boolean;
  payment_intent: string;
  payment_settings: PaymentSettingsJson;
  period_end: number;
  period_start: number;
  post_payment_credit_notes_amount: number;
  pre_payment_credit_notes_amount: number;
  quote:null;
  receipt_number: string;
  starting_balance: number;
  statement_descriptor:null;
  status: Status;
  status_transitions: StatusTransitionsJson;
  subscription: string | null;
  subtotal: number;
  tax: null;
  total: number;
  total_discount_amounts:[];
  total_tax_amounts:[];
  transfer_data:null;
  webhooks_delivered_at:number;
}

export default class Invoice {
  id = 'unknown';
  created: DateTime = DateTime.local();
  currency = '';
  due_date: DateTime = DateTime.local();
  hosted_invoice_url = '';
  total = 0;
  paid = false;
  number = 'unknown';
  status: Status = "open";


  static fromJSON(json: InvoiceJson): Invoice {
    //console.debug("Invoice.fromJSON", json);
    const response = Object.create(Invoice.prototype);
    return Object.assign(response, json, {
      created: json.created ? DateTime.fromSeconds(json.created) : DateTime.local(),
      due_date: json.due_date ? DateTime.fromSeconds(json.due_date) : DateTime.local(),
    });
  }
}
