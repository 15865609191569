/*
 * Ripple effect fuctionality
 */

declare global {
  interface HTMLElement {
    clickRipple: (this: HTMLElement, event: MouseEvent) => void;
  }
}

export default {
  bind: function (el: HTMLElement): void {
    // Add specific CSS styles to element
    el.style.overflow = 'hidden';
    el.style.position = 'relative';
    el.style.zIndex = '1';

    // Setup ripple functionality
    el.clickRipple = function (this: HTMLElement, event: MouseEvent) {
      const cssClass = 'click-ripple';
      const ripple: NodeListOf<Element> = this.querySelectorAll('.' + cssClass);
      let d;

      // If the ripple element doesn't exist in this element, add it..
      if (ripple.length === 0) {
        const elChild = document.createElement('span');
        elChild.classList.add(cssClass);

        this.insertBefore(elChild, this.firstChild);
      } else { // ..else remove .animate class from ripple element
        ripple[0].classList.remove('animate');
      }

      // Get the ripple element
      const rippleElement: HTMLElement = this.querySelectorAll<HTMLElement>('.' + cssClass)[0];

      // If the ripple element doesn't have dimensions, set them accordingly
      if ((getComputedStyle(rippleElement).height === '0px') || (getComputedStyle(rippleElement).width === '0px')) {
        d = Math.max(this.offsetWidth, this.offsetHeight);

        rippleElement.style.height = d + 'px';
        rippleElement.style.width = d + 'px';
      }

      // Get coordinates for our ripple element
      const x = event.pageX - (this.getBoundingClientRect().left + window.scrollX) - parseFloat(getComputedStyle(rippleElement).width.replace('px', '')) / 2;
      const y = event.pageY - (this.getBoundingClientRect().top + window.scrollY) - parseFloat(getComputedStyle(rippleElement).height.replace('px', '')) / 2;

      // Position the ripple element and add the class .animate to it
      rippleElement.style.top = y + 'px';
      rippleElement.style.left = x + 'px';
      rippleElement.classList.add('animate');
    };

    // Attach the click event to the element
    el.addEventListener('click', el.clickRipple);
  },
  unbind: function (el: HTMLElement): void {
    el.removeEventListener('click', el.clickRipple);
  },
};
