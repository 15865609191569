/*
 * Main file
 */

import axios, {AxiosError, AxiosRequestConfig} from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

// Vue, Vue Router, App and Vuex store
import Vue from 'vue';
import router from './router';
import VueGtm from '@gtm-support/vue2-gtm';

import App from './App.vue';
import store from './store';

// Bootstrap Vue
import BootstrapVue from 'bootstrap-vue';

// Custom components
import BaseLayoutModifier from '@/components/BaseLayoutModifier.vue';
import BaseBlock from '@/components/BaseBlock.vue';
import BaseBackground from '@/components/BaseBackground.vue';
import BasePageHeading from '@/components/BasePageHeading.vue';
import BaseNavigation from '@/components/BaseNavigation.vue';

// Custom directives
import clickRipple from '@/directives/clickRipple';
import toggleClass from '@/directives/toggleClass';

import VueCookies from 'vue-cookies';
import VueCompositionAPI from '@vue/composition-api';

Vue.use(VueCookies);
Vue.use(VueCompositionAPI);

Vue.config.devtools = process.env.VUE_APP_DEVTOOLS === "true" || process.env.NODE_ENV === "development";

// Register global plugins
Vue.use(BootstrapVue);

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CONTAINER_ID,
  enabled: process.env.VUE_APP_GTM_ENABLED,
  debug: process.env.VUE_APP_GTM_DEBUG,
  vueRouter: router,
});

// Register global components
Vue.component('BaseLayoutModifier', BaseLayoutModifier);
Vue.component('BaseBlock', BaseBlock);
Vue.component('BaseBackground', BaseBackground);
Vue.component('BasePageHeading', BasePageHeading);
Vue.component('BaseNavigation', BaseNavigation);

// Register global directives
Vue.directive('click-ripple', clickRipple);
Vue.directive('toggle-class', toggleClass);

// Disable tip shown in dev console when in development mode
Vue.config.productionTip = false;

import VueHighlightJS from 'vue-highlightjs';
Vue.use(VueHighlightJS);

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// Craft new application
new Vue({
  store,
  router,
  render: h => h(App),
  created: async function () {
    await this.$store.dispatch('fetchCurrentUser');
  },
}).$mount('#app');

axios.interceptors.response.use(undefined, function (error: AxiosError) {
  if (!error) {
    return;
  }
  const originalRequest = error.config as AxiosRequestConfig & { retry: boolean};
  if (error.response?.status === 401 && !originalRequest.retry ) {

    originalRequest.retry = true;
    store.dispatch('logout')
      .then(() => router.push({name: 'login'}));
  }
  return Promise.reject(error);
});

// Add a default converter from an Axios error to a Laravel error
axios.interceptors.response.use(function (response) {
    return response;
  }, function (error: AxiosError<LaravelErrorJson>) {
    return Promise.reject(LaravelError.fromAxios(error));
  });

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  attachProps: true,
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true,
  },
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT ?? 'local',
  release: process.env.VUE_APP_SENTRY_RELEASE ?? 'unknown',
});

import Echo from 'laravel-echo';
import Pusher from "pusher-js";
import LaravelError, {LaravelErrorJson} from "@/api/model/LaravelError";

declare global {
    interface Window {
      // eslint-disable-next-line @typescript-eslint/naming-convention
        Pusher: Pusher;
      // eslint-disable-next-line @typescript-eslint/naming-convention
        Echo: Echo;
    }
}

window.Pusher = require("pusher-js");

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: 6001,
  forceTLS: false,
  authorizer: (channel: { name: unknown }) => ({
    authorize: (socketId: unknown, callback: (error: boolean, data: unknown) => void) => {
      axios.post('/broadcasting/auth', {
        socket_id: socketId,
        channel_name: channel.name,
      })
        .then(response => {
          callback(false, response.data);
        })
        .catch(error => {
          callback(true, error);
        });
    },
  }),
});

