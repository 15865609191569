import Customer, {CustomerJson} from "@/api/model/stripe/Customer";

export interface SetupIntentJson {
 client_secret: string;
 customer: CustomerJson | null;
}

export class SetupIntent {
  client_secret = '';
  customer: Customer | null = null;

    static fromJSON(json: SetupIntentJson): SetupIntent {
    //console.debug("SetupIntent.fromJSON", json);
    const response = Object.create(SetupIntent.prototype);
    return Object.assign(response, json, {
      customer: json.customer ? Customer.fromJSON(json.customer) : null,
    });
  }
}
