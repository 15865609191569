import {DateTime} from "luxon";
import {FromJsonFactory} from "@/api/model/PaginatedResponse";

export interface CampaignJson {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  subject: string;
  body: string;
  scheduled_time: string;
  live: boolean;
  customer_segment_id: number;
  coupon_settings: string;
  layout: string;
}

export default class Campaign {
  id = 0;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  name = '';
  subject = '';
  body = '';
  scheduled_time = DateTime.local();
  live = false;
  customer_segment_id = 0;
  coupon_settings = "{}";
  layout = 'standard';

  static fromJSON(json: CampaignJson): Campaign {
    //console.debug("Dashboard.fromJSON", json);
    const response = Object.create(Campaign.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.local(),
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : DateTime.local(),
      scheduled_time: json.scheduled_time ? DateTime.fromISO(json.scheduled_time) : DateTime.local(),
    });
  }
}

export class CampaignFactory implements  FromJsonFactory<Campaign, CampaignJson> {
  fromJSON(json: CampaignJson): Campaign {
    return Campaign.fromJSON(json);
  }

}
