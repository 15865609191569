export interface Address {
  first_name: string;
  last_name: string ;
  company: string ;
  address_1: string ;
  address_2: string ;
  city: string ;
  state: string ;
  postcode: string ;
  country: string ;
}

export interface WooAddressJson extends Address {
  first_name: string;
  last_name: string ;
  company: string ;
  address_1: string ;
  address_2: string ;
  city: string ;
  state: string ;
  postcode: string ;
  country: string ;
  email: string | null;
  phone: string  | null;
}

export class WooAddress implements Address {
    first_name = '';
    last_name = '';
    company = '';
    address_1 = '';
    address_2 = '';
    city = '';
    state = '';
    postcode = '';
    country = '';
    email = '' || null;
    phone = '' || null;

    static fromJSON(json: WooAddressJson): WooAddress {
        //console.debug("WooAddress.fromJSON()", json);
        const response = Object.create(WooAddress.prototype);
        return Object.assign(response, json, {
        });
    }
}
