import {DateTime} from "luxon";
import {FromJsonFactory} from "@/api/model/PaginatedResponse";

export interface CustomerSegmentJson {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  rules: string;
}

export default class CustomerSegment {
  id = 0;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  name = '';
  description = '';
  rules = '';

  static fromJSON(json: CustomerSegmentJson): CustomerSegment {
    //console.debug("CustomerSegment.fromJSON", json);
    const response = Object.create(CustomerSegment.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.local(),
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : DateTime.local(),
    });
  }
}

export class CustomerSegmentFactory implements  FromJsonFactory<CustomerSegment, CustomerSegmentJson> {
  fromJSON(json: CustomerSegmentJson): CustomerSegment {
    return CustomerSegment.fromJSON(json);
  }
}
