




















import Vue from "vue";
import OrderService from "@/api/OrderService";
import DashboardService from "@/api/DashboardService";
import GoogleAccountService from "@/api/GoogleAccountService";
import EmailSequenceService from "@/api/EmailSequenceService";
import CampaignService from "@/api/CampaignService";
import WooProductService from "@/api/WooProductService";
import CustomerSegmentService from "@/api/CustomerSegmentService";
import WooSiteService from "@/api/WooSiteService";
import WooOrderService from "@/api/WooOrderService";
import AuthService from "@/api/AuthService";
import DomainService from "./api/DomainService";
import WooCustomerService from "@/api/WooCustomerService";
import WooCouponService from "@/api/WooCouponService";
import WooSubscriptionService from "@/api/WooSubscriptionService";
import InvoiceService from "@/api/InvoiceService";
import OrganisationService from "@/api/OrganisationService";
import StatsService from "@/api/StatsService";
import CartService from "@/api/CartService";
import PricePlanService from "@/api/PricePlanService";

export default Vue.extend({
  name: 'App',
  provide: {
    authService: new AuthService(),
    cartService: new CartService(),
    customerSegmentService: new CustomerSegmentService(),
    campaignService: new CampaignService(),
    dashboardService: new DashboardService(),
    domainService: new DomainService(),
    emailSequenceService: new EmailSequenceService(),
    googleAccountService: new GoogleAccountService(),
    invoiceService: new InvoiceService(),
    orderService: new OrderService(),
    organisationService: new OrganisationService(),
    pricePlanService: new PricePlanService(),
    statsService: new StatsService(),
    wooCouponService: new WooCouponService(),
    wooCustomerService: new WooCustomerService(),
    wooProductService: new WooProductService(),
    wooOrderService: new WooOrderService(),
    wooSiteService: new WooSiteService(),
    wooSubscriptionService: new WooSubscriptionService(),

  },
  watch: {
    $route(to) {
      document.title = `Blue Odin | ${to.meta.title || to.name || 'Page' }`;
    },
  },
});
