import axios, {AxiosResponse} from "axios";
import Register from "./model/Register";
import User, {UserJson} from "@/api/model/User";
import {Resource} from "@/api/model/Resource";

class LoginRequest {
  private email: string;
  private password: string;
  private remember: boolean;
  constructor(email: string, password: string, rememberMe: boolean) {
    this.email = email;
    this.password = password;
    this.remember = rememberMe;
  }
}

class ForgotPasswordRequest {
  private email: string;
  constructor(email: string) {
    this.email = email;
  }
}

class ResetPasswordRequest {
  private email: string;
  private password: string;
  private password_confirmation: string;
  private token: string;
  constructor(email: string, password: string, passwordConfirmation: string, token: string) {
    this.email = email;
    this.password = password;
    this.password_confirmation = passwordConfirmation;
    this.token = token;
  }
}

interface LoginResponseJson {
  access_token: string;
  user: UserJson;
}

interface ResetPasswordJson {
  message: string;
}

interface ForgotPasswordJson {
  message: string;
}

interface VerifyEmailJson {
  message: string;
}

export class LoginResponse {
  access_token = '';
  user: User = new User();
  static fromJSON(json: LoginResponseJson): LoginResponse {
    console.debug("LoginResponse.fromJSON()", json);
    const response = Object.create(LoginResponse.prototype);
    return Object.assign(response, json, {
      user: User.fromJSON(json.user),
    });
  }
}

export class ResetPasswordResponse {
  message = '';

  static fromJSON(json: ResetPasswordJson): ResetPasswordResponse {
    console.debug("ResetPasswordResponse.fromJSON()", json);
    const response = Object.create(ResetPasswordResponse.prototype);
    return Object.assign(response, json, {
      message: json.message,
    });
  }
}
export class ForgotPasswordResponse {
  message = '';

  static fromJSON(json: ForgotPasswordJson): ForgotPasswordResponse {
    console.debug("ForgotPasswordResponse.fromJSON()", json);
    const response = Object.create(ForgotPasswordResponse.prototype);
    return Object.assign(response, json, {
      message: json.message,
    });
  }
}

export class VerifyEmailResponse {
  message = '';

  static fromJSON(json: ForgotPasswordJson): VerifyEmailJson {
    console.debug("VerifyEmailResponse.fromJSON()", json);
    const response = Object.create(VerifyEmailResponse.prototype);
    return Object.assign(response, json, {
      message: json.message,
    });
  }
}

export default class AuthService {

  public static async register(user: Register): Promise<User> {
    return axios.get('/sanctum/csrf-cookie').then(() => {
    return axios.post<UserJson>('/register', user)
      .then((resp) => User.fromJSON(resp.data));
    });
  }

  public static async login(email: string, password: string, rememberMe: boolean): Promise<LoginResponse> {
    const authData = new LoginRequest(email, password, rememberMe);
    return axios.get('/sanctum/csrf-cookie').then(() => {
      return axios.post<LoginResponseJson>('/login', authData)
        .then((resp: AxiosResponse<LoginResponseJson>) => LoginResponse.fromJSON(resp.data));
    });
  }

  public static async logout(): Promise<AxiosResponse<LoginResponseJson>> {
    return axios.get<LoginResponseJson>('/logout');
  }

  public static async currentUser(): Promise<User> {
    return axios.get<Resource<UserJson>>('/api/auth/currentuser')
      .then((resp: AxiosResponse<Resource<UserJson>>) => User.fromJSON(resp.data.data));
  }

  public static async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    const forgotPasswordData = new ForgotPasswordRequest(email);
    console.log("forgotPD", forgotPasswordData);
    return axios.get('/sanctum/csrf-cookie').then(() => {
      return axios.post<ForgotPasswordJson>('/api/auth/forgot-password', forgotPasswordData)
        .then((resp: AxiosResponse<ForgotPasswordJson>) => ForgotPasswordResponse.fromJSON(resp.data));
    });
  }

  public static async resetPassword(email: string, password: string, passwordConfirmation: string, token: string): Promise<ResetPasswordResponse> {
    const resetPasswordData = new ResetPasswordRequest(email, password, passwordConfirmation, token);
    return axios.get('/sanctum/csrf-cookie').then(() => {
      return axios.post<ResetPasswordJson>('/api/auth/reset-password', resetPasswordData)
        .then((resp: AxiosResponse<ResetPasswordJson>) => ResetPasswordResponse.fromJSON(resp.data));
    });
  }

  public static async changePassword(oldPassword: string, newPassword: string): Promise<ResetPasswordResponse> {
    return axios.post<ResetPasswordJson>('/api/auth/change-password', {old_password: oldPassword, new_password: newPassword})
      .then((resp: AxiosResponse<ResetPasswordJson>) => ResetPasswordResponse.fromJSON(resp.data));
  }

  public async verifyEmail(id: string, hash: string, signature?: string, expires?: string): Promise<VerifyEmailResponse> {
    return axios.get<VerifyEmailJson>(`/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`)
      .then((resp: AxiosResponse<VerifyEmailJson>) => VerifyEmailResponse.fromJSON(resp.data));
  }

  public async resendVerify(): Promise<VerifyEmailResponse> {
    return axios.post<VerifyEmailJson>('/email/resend')
      .then((resp: AxiosResponse<VerifyEmailJson>) => VerifyEmailResponse.fromJSON(resp.data));
  }
}
