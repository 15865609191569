
































import Vue, {PropOptions} from "vue";

export default Vue.extend({
  name: 'BaseNavigation',
  props: {
    nodes: {
      type: Array,
      description: 'The nodes of the navigation',
    }  as PropOptions<Array<{name: string, badge: string, heading: string}>>,
    subMenu: {
      type: Boolean,
      default: false,
      description: 'If true, a submenu will be rendered',
    } as PropOptions<boolean>,
    dark: {
      type: Boolean,
      default: false,
      description: 'Dark mode for menu',
    } as PropOptions<boolean>,
    horizontal: {
      type: Boolean,
      default: false,
      description: 'Horizontal menu in large screen width',
    } as PropOptions<boolean>,
    horizontalHover: {
      type: Boolean,
      default: false,
      description: 'Hover mode for horizontal menu',
    } as PropOptions<boolean>,
    horizontalCenter: {
      type: Boolean,
      default: false,
      description: 'Center mode for horizontal menu',
    } as PropOptions<boolean>,
    horizontalJustify: {
      type: Boolean,
      default: false,
      description: 'Justify mode for horizontal menu',
    } as PropOptions<boolean>,
  },
  computed: {
    classContainer () {
      return {
        'nav-main': !this.subMenu,
        'nav-main-submenu': this.subMenu,
        'nav-main-dark': this.dark,
        'nav-main-horizontal': this.horizontal,
        'nav-main-hover': this.horizontalHover,
        'nav-main-horizontal-center': this.horizontalCenter,
        'nav-main-horizontal-justify': this.horizontalJustify,
      };
    },
  },
  methods: {
    subIsActive (paths: Array<string> | string) {
      const activePaths = Array.isArray(paths) ? paths : [paths];

      return activePaths.some(path => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    linkClicked (e: MouseEvent, submenu: HTMLElement) {
      // Get window width
      const windowW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (!submenu) {
        // If we are in mobile, close the sidebar
        if (windowW < 992) {
          this.$store.commit('sidebar', {mode: 'close'});
        }
        return;
      }

      // Get closest li element
      const el = (e?.target as Element)?.closest('li');

      if (el === null) {
        return;
      }

      // Check if we are in a large screen, have horizontal navigation and hover is enabled
      if (windowW > 991 && this.horizontal && this.horizontalHover) {
        return;
      }

      if (el.classList.contains('open')) {
        // If submenu is open, close it..
        el.classList.remove('open');
      } else {
        const closest = el.closest('ul');
        // .. else if submenu is closed, close all other (same level) submenus first before open it
        const children = closest? closest.children : [];
        Array.from(children).forEach((element: Element) => {
          element.classList.remove('open');
        });

        el.classList.add('open');
      }
    },
  },
});
