import { render, staticRenderFns } from "./AddWidgetList.vue?vue&type=template&id=70870f50&scoped=true&"
import script from "./AddWidgetList.vue?vue&type=script&lang=ts&"
export * from "./AddWidgetList.vue?vue&type=script&lang=ts&"
import style0 from "./AddWidgetList.vue?vue&type=style&index=0&id=70870f50&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70870f50",
  null
  
)

export default component.exports