import axios from "axios";
import "@/lib";
import {WooSite} from "@/api/model/WooSite";
import {WooProduct, WooProductJson} from "@/api/model/WooProduct";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import {DateRange} from "@/api/model/DateRange";

interface DayDataJson {
  revenue: number;
  cost_of_goods: number;
  profit: number;
  orders: number;
  units: number;
  refunds: number;
  coupons: number;
  shipping_fees: number;
  other_fees: number;
  fees: number;
  refunds_units: number;
}


export class DayData {
  revenue = 0;
  orders = 0;
  units = 0;
  refunds = 0;
  coupons = 0;
  shipping_fees = 0;
  other_fees = 0;
  fees = 0;
  cost_of_goods = 0;
  profit = 0;
  refunds_units = 0;

  static fromJson(json: DayDataJson): DayData {
    //console.debug("DayData.fromJSON()", json);
    const response = Object.create(DayData.prototype);
    return Object.assign(response, json, {
    });
  }

    public get refundsPercentage(): number {
      const decreased = this.revenue - (this.revenue - this.refunds);
      return this.revenue > 0 ? (Math.round((decreased / this.revenue * 100) * 100) / 100) : 0;
  }
}

export class SalesGraphData {
  static fromJSON(data: SalesGraphDataJson): SalesGraphData {
    //console.debug("SalesGraphData.fromJSON()", data);
    const salesData =  new SalesGraphData();
    Object.keys(data.current).forEach(key => salesData.data[key] = DayData.fromJson(data.current[key]));
    if (data.previous) {
      Object.keys(data.previous).forEach(key => salesData.previous[key] = DayData.fromJson(data.previous[key]));
    }
    salesData.units = data.units;
    return salesData;
  }
  data: {
    [key: string]: DayData;
  } = {};
  previous: {
    [key: string]: DayData;
  } = {};
  units = "day" as "hour" | "day";

  public getRevenue(): number {
    return Object.keys(this.data)
    .map(key => +this.data[key].revenue)
    .sum();
  }
  public getRefunds(): number {
    return Object.keys(this.data)
      .map(key => +this.data[key].refunds)
      .sum();
  }
  public getRefundsUnits(): number {
    return Object.keys(this.data)
      .map(key => +this.data[key].refunds_units)
      .sum();
  }
  public getCostOfGoods(): number {
    return Object.keys(this.data)
      .map(key => +this.data[key].cost_of_goods)
      .sum();
  }
  public getProfit(): number {
    return Object.keys(this.data)
      .map(key => +this.data[key].profit)
      .sum();
  }
  public getOrders(): number {
    return Object.keys(this.data)
      .map(key => +this.data[key].orders)
      .sum();
  }
  public getCoupons(): number {
    return Object.keys(this.data)
      .map(key => +this.data[key].coupons)
      .sum();
  }
}

interface SalesGraphDataJson {
  current: {
    [key: string]: DayDataJson;
  };
  previous: {
    [key: string]: DayDataJson;
  };
  units: "day" | "hour";
}

interface ProductsResponseJson {
  stats: SalesGraphDataJson;
  products: WooProductJson[];
}

export class ProductsResponse {
  stats: SalesGraphData = new SalesGraphData();
  products: WooProduct[] = [];

  static fromJSON(json: ProductsResponseJson): ProductsResponse {
    console.debug("ProductsResponse.fromJSON()", json);
    const response = Object.create(ProductsResponse.prototype);
    return Object.assign(response, json, {
      stats: SalesGraphData.fromJSON(json.stats),
      products: json.products.map(product => WooProduct.fromJSON(product)),
    });
  }
}

interface StatResponseJson {
  current: number;
  previous: number;
}

class StatResponse {
  current = 0;
  previous = 0;
  static fromJSON(json: StatResponseJson): StatResponse {
    //console.debug("StatResponse.fromJSON()", json);
    const response = Object.create(StatResponse.prototype);
    return Object.assign(response, json, {
    });
  }
}

type StatType = "costOfGoods" | 'orderCount' | 'profit' | 'revenue' | 'refunds' | 'refundUnits' | 'coupons' | 'unitCount' | 'avgOrderValue' | 'avgUnitsCountPerOrder' | 'profitMargin' | 'avgProfitPerOrder';

export default class OrderService {

  getSalesGraphData(siteId: number | "all" | null, range: DateRange): Promise<SalesGraphData> {
    if (siteId === null) {
      return Promise.resolve(new SalesGraphData());
    }
    return axios.get<SalesGraphDataJson>(`/api/sites/${siteId}/orders/summary/by_day?${range.getParams()}`)
      .then((resp) => SalesGraphData.fromJSON(resp.data));
  }

  getSalesStat(site: WooSite, stat: StatType, range: DateRange): Promise<StatResponse> {
    return axios.get<StatResponseJson>(`/api/sites/${site.id}/orders/stats/${stat}?${range.getParams()}`)
      .then((resp) => StatResponse.fromJSON(resp.data));
  }

  getSalesStatById(siteId: number | "all", stat: StatType, range: DateRange): Promise<StatResponse> {
    return axios.get<StatResponseJson>(`/api/sites/${siteId}/orders/stats/${stat}?${range.getParams()}`)
      .then((resp) => StatResponse.fromJSON(resp.data));
  }

  getSalesByProduct(site: WooSite, range: DateRange): Promise<WooProduct[]> {
    return axios.get<ResourceCollection<WooProductJson>>(`/api/sites/${site.id}/products/summary/by_product?${range.getParams()}`)
      .then((resp) => resp.data.data.map(json => WooProduct.fromJSON(json)));
  }
}
