import {DateTime} from "luxon";

export interface WooSubscriptionItemJson {
  id: number;
  created_at: string | null;
  updated_at: string | null;
  name: string;
  price: string;
  quantity: number;
  sku: string;
  subtotal: string;
  subtotal_tax: string;
  tax_class: string;
  total: string;
  total_tax: string;
  woo_item_id: number;
  woo_product_id: number;
  woo_subscription_id: number;
  woo_variation_id: number;
}

export class WooSubscriptionItem {

  id = '';
  created_at: DateTime | null = null;
  updated_at: DateTime | null = null;
  name = '';
  price = '';
  quantity = '';
  sku = '';
  subtotal = '';
  subtotal_tax = '';
  tax_class = '';
  total = '';
  total_tax = '';
  woo_item_id = '';
  woo_product_id = '';
  woo_subscription_id = '';
  woo_variation_id = '';

  static fromJSON(json: WooSubscriptionItemJson): WooSubscriptionItem {
    //console.debug("WooOrderItem.fromJSON()", json);
    const response = Object.create(WooSubscriptionItem.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? new Date(json.created_at) : null,
      updated_at: json.updated_at ? new Date(json.updated_at) : null,
    });
  }
}
