import {DateTime} from "luxon";

export class WooCoupon {
    id = 0;
    code = '';
    description = '';
    created_at: DateTime | null = null;
    updated_at: DateTime | null = null;
    woo_id = '';
    amount = 0;
    date_created: DateTime | null = null;
    date_created_gmt: DateTime | null = null;
    date_modified: DateTime | null = null;
    date_modified_gmt: DateTime | null = null;
    discount_type = '';
    date_expires: DateTime | null = null;
    date_expires_gmt: DateTime | null = null;
    usage_count = '';
    individual_use = '';
    usage_limit = 0;
    usage_limit_per_user = 0;
    limit_usage_to_x_items = true;
    free_shipping = '';
    exclude_sale_items = '';
    minimum_amount = '';
    maximum_amount = '';
    woo_site_id = '';

    static fromJSON(json: WooCouponJson): WooCoupon {
        //console.debug("WooCoupon.fromJSON", json);
        const response = Object.create(WooCoupon.prototype);
        return Object.assign(response, json, {
            created_at: json.created_at ? new Date(json.created_at) : null,
            updated_at: json.updated_at ? new Date(json.updated_at) : null,
            date_created: json.date_created ? DateTime.fromISO(json.date_created) : null,
            date_created_gmt: json.date_created_gmt ? DateTime.fromISO(json.date_created_gmt) : null,
            date_modified: json.date_modified ? DateTime.fromISO(json.date_modified) : null,
            date_modified_gmt: json.date_modified_gmt ? DateTime.fromISO(json.date_modified_gmt) : null,
            date_expires: json.date_expires ? DateTime.fromISO(json.date_expires) : null,
            date_expires_gmt: json.date_expires_gmt ? DateTime.fromISO(json.date_expires_gmt) : null,
        });
    }

    public discountDescription(formatter: Intl.NumberFormat): string {
        switch (this.discount_type) {
            case 'percent':
                return this.amount + '%';
            case 'fixed_cart':
                return formatter.format(this.amount);
            case 'fixed_product':
                return formatter.format(this.amount);
            default:
                return this.discount_type;
        }
    }
}

export interface WooCouponJson {
    id: number;
    code: string;
    description: string;
    created_at: string;
    updated_at: string;
    woo_id: string;
    amount: number;
    date_created: string;
    date_created_gmt: string;
    date_modified: string;
    date_modified_gmt: string;
    discount_type: string;
    date_expires: string;
    date_expires_gmt: string;
    usage_count: string;
    individual_use: string;
    usage_limit: number;
    usage_limit_per_user: number;
    limit_usage_to_x_items: boolean;
    free_shipping: string;
    exclude_sale_items: string;
    minimum_amount: string;
    maximum_amount: string;
    woo_site_id: string;
}
