export interface AddressJson {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
}

export default class Address {
  city = 'unknown';
  country = 'unknown';
  line1= 'unknown';
  line2 = 'unknown';
  postal_code = 'unknown';
  state = 'unknown';


  constructor(line1: string, line2: string, city: string, state: string, postalCode: string, country: string) {
    this.city = city;
    this.country = country;
    this.line1 = line1;
    this.line2 = line2;
    this.postal_code = postalCode;
    this.state = state;
  }

  static fromJSON(json: AddressJson): Address {
    //console.debug("Address.fromJSON", json);
    const response = Object.create(Address.prototype);
    return Object.assign(response, json, {
    });
  }
}
