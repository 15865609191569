import Organisation, {OrganisationJson} from "@/api/model/Organisation";
import {DateTime} from "luxon";

export interface UserJson {
  name: string;
  email: string;
  created_at: string;
  email_verified_at: string;
  updated_at: string;
  current_organisation_id: number;
  current_organisation: OrganisationJson | null;
  utm_campaign: string;
  referral_url: string;
}

export default class User {
  created_at: DateTime|null = null;
  updated_at: DateTime|null = null;
  id: number|null = null;
  current_organisation: Organisation|null = null;
  current_organisation_id = 0;


  name = '';
  email = '';
  utm_campaign = '';
  referral_url = '';

  toJSON(): UserJson {
    return Object.assign({} as UserJson, this, {
    });
  }

  static fromJSON(json: UserJson): User {
    //console.debug("User.fromJSON()", json);
    const user = Object.create(User.prototype);
    return Object.assign(user, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
      current_organisation: json.current_organisation ? Organisation.fromJSON(json.current_organisation) : null,
    });
  }
}
