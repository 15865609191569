











































import Vue, {PropOptions} from "vue";

const BaseBlock = Vue.extend({
  name: 'BaseBlock',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'The HTML tag of the component (div, a)',
    } as PropOptions<string>,
    title: {
      type: String,
      description: 'The title of the block',
    } as PropOptions<string>,
    subtitle: {
      type: String,
      description: 'The subtitle of the block',
    } as PropOptions<string>,
    bordered: {
      type: Boolean,
      default: false,
      description: 'Bordered block style',
    } as PropOptions<boolean>,
    rounded: {
      type: Boolean,
      default: false,
      description: 'Rounded block style',
    } as PropOptions<boolean>,
    themed: {
      type: Boolean,
      default: false,
      description: 'Themed block style',
    } as PropOptions<boolean>,
    transparent: {
      type: Boolean,
      default: false,
      description: 'Transparent block style',
    } as PropOptions<boolean>,
    fxShadow: {
      type: Boolean,
      default: false,
      description: 'Shadow fx block style',
    } as PropOptions<boolean>,
    fxPop: {
      type: Boolean,
      default: false,
      description: 'Pop fx block style',
    } as PropOptions<boolean>,
    fxRotateRight: {
      type: Boolean,
      default: false,
      description: 'Rotate right fx block style',
    } as PropOptions<boolean>,
    fxRotateLeft: {
      type: Boolean,
      default: false,
      description: 'Rotate left fx block style',
    } as PropOptions<boolean>,
    linkShadow: {
      type: Boolean,
      default: false,
      description: 'Shadow style for block links',
    } as PropOptions<boolean>,
    linkPop: {
      type: Boolean,
      default: false,
      description: 'Pop style for block links',
    } as PropOptions<boolean>,
    linkRotate: {
      type: Boolean,
      default: false,
      description: 'Rotate style for block links',
    } as PropOptions<boolean>,
    headerClass: {
      type: String,
      description: 'Add additional classes to default block-header',
    } as PropOptions<string>,
    optionsClass: {
      type: String,
      description: 'Add additional classes to default block-options',
    } as PropOptions<string>,
    contentClass: {
      type: String,
      description: 'Add additional classes to default block-content',
    } as PropOptions<string>,
    headerBg: {
      type: Boolean,
      default: false,
      description: 'Add the default header background',
    } as PropOptions<boolean>,
    headerRtl: {
      type: Boolean,
      default: false,
      description: 'Reverse the order of elements in header',
    } as PropOptions<boolean>,
    contentFull: {
      type: Boolean,
      default: false,
      description: 'Add full padding to the bottom of the default block-content',
    } as PropOptions<boolean>,
    ribbon: {
      type: [Boolean, String],
      default: false,
      description: 'Enable the ribbon or enable it and also specify its content by setting a string value.',
    } as PropOptions<boolean>,
    ribbonLeft: {
      type: Boolean,
      default: false,
      description: 'Position ribbon to the left',
    } as PropOptions<boolean>,
    ribbonBottom: {
      type: Boolean,
      default: false,
      description: 'Position ribbon to the bottom',
    } as PropOptions<boolean>,
    ribbonBookmark: {
      type: Boolean,
      default: false,
      description: 'Set the bookmark type to your ribbon',
    } as PropOptions<boolean>,
    ribbonModern: {
      type: Boolean,
      default: false,
      description: 'Set the modern type to your ribbon',
    } as PropOptions<boolean>,
    ribbonVariant: {
      type: String,
      default: 'primary',
      description: 'Set a different ribbon variant (primary, info, warning, danger, success, glass)',
    } as PropOptions<"primary" | "info" | "warning" | "danger" | "success" | "glass">,
    modeLoading: {
      type: Boolean,
      default: false,
      description: 'Loading block mode',
    } as PropOptions<boolean>,
    modeFullscreen: {
      type: Boolean,
      default: false,
      description: 'Fullscreen block mode',
    } as PropOptions<boolean>,
    modePinned: {
      type: Boolean,
      default: false,
      description: 'Pinned block mode',
    } as PropOptions<boolean>,
    modeContentHide: {
      type: Boolean,
      default: false,
      description: 'Hide block’s content',
    } as PropOptions<boolean>,
    modeHide: {
      type: Boolean,
      default: false,
      description: 'Hide block itself',
    } as PropOptions<boolean>,
    btnOptionFullscreen: {
      type: Boolean,
      defaul: false,
      description: 'Enable the fullscreen mode button',
    } as PropOptions<boolean>,
    btnOptionPinned: {
      type: Boolean,
      defaul: false,
      description: 'Enable the pinned mode button',
    } as PropOptions<boolean>,
    btnOptionContent: {
      type: Boolean,
      defaul: false,
      description: 'Enable the content toggle button',
    } as PropOptions<boolean>,
    btnOptionClose: {
      type: Boolean,
      defaul: false,
      description: 'Enable the close mode button',
    } as PropOptions<boolean>,
  },
  data () {
    return {
      // If the block is in loading mode
      //optionLoading: this.modeLoading,

      // If the block is in fullscreen mode
      optionFullscreen: this.modeFullscreen as boolean,

      // If the block is in pinned mode
      optionPinned: this.modePinned as boolean,

      // If the default block-content is hidden
      optionContentHide: this.modeContentHide as boolean,

      // If block itself is hidden
      optionHide: this.modeHide as boolean,
    };
  },
  computed: {
    classContainer (): Record<string, unknown> {
      return {
        'block-bordered': this.bordered,
        'block-rounded': this.rounded,
        'block-themed': this.themed,
        'block-transparent': this.transparent,
        'block-fx-shadow': this.fxShadow,
        'block-fx-pop': this.fxPop,
        'block-fx-rotate-right': this.fxRotateRight,
        'block-fx-rotate-left': this.fxRotateLeft,
        'block-link-shadow': this.linkShadow,
        'block-link-pop': this.linkPop,
        'block-link-rotate': this.linkRotate,
        'block-mode-loading': this.optionLoading,
        'block-mode-fullscreen': this.optionFullscreen,
        'block-mode-pinned': this.optionPinned,
        'block-mode-hidden': this.optionContentHide,
        'd-none': this.optionHide,
      };
    },
    classContainerHeader (): Record<string, unknown> {
      return {
        'block-header-default': this.headerBg,
        'block-header-rtl': this.headerRtl,
        [this.headerClass]: this.headerClass,
      };
    },
    classContainerOptions (): Record<string, unknown> {
      return {
        [this.optionsClass]: this.optionsClass,
      };
    },
    classContainerContent (): Record<string, unknown> {
      return {
        'block-content-full': this.contentFull,
        'ribbon': this.ribbon,
        'ribbon-left': this.ribbon && this.ribbonLeft,
        'ribbon-bottom': this.ribbon && this.ribbonBottom,
        'ribbon-bookmark': this.ribbon && this.ribbonBookmark,
        'ribbon-modern': this.ribbon && this.ribbonModern,
        [`ribbon-${this.ribbonVariant}`]: this.ribbon && this.ribbonVariant,
        [this.contentClass]: this.contentClass,
      };
    },
    optionLoading (): boolean {
      return this.modeLoading;
    },
  },
  methods: {
    fullscreenToggle (): void {
      this.optionPinned = false;
      this.optionFullscreen = !this.optionFullscreen;
    },
    fullscreenOn (): void {
      this.optionPinned = false;
      this.optionFullscreen = true;
    },
    fullscreenOff (): void {
      this.optionPinned = false;
      this.optionFullscreen = false;
    },
    pinnedToggle (): void {
      this.optionFullscreen = false;
      this.optionPinned = !this.optionPinned;
    },
    pinnedOn (): void {
      this.optionFullscreen = false;
      this.optionPinned = true;
    },
    pinnedOff (): void {
      this.optionFullscreen = false;
      this.optionPinned = false;
    },
    contentToggle (): void {
      this.optionContentHide = !this.optionContentHide;
    },
    contentShow (): void {
      this.optionContentHide = false;
    },
    contentHide (): void {
      this.optionContentHide = true;
    },
    stateToggle (): void {
      this.optionLoading = !this.optionLoading;
    },
    stateLoading (): void {
      this.optionLoading = true;
    },
    stateNormal (): void {
      this.optionLoading = false;
    },
    open (): void {
      this.optionHide = false;
    },
    close (): void {
      this.optionHide = true;
    },
  },
});

export default BaseBlock;
