/*
 * Toggle Class functionality
 */

import {DirectiveBinding} from "vue/types/options";

declare global {
  interface HTMLElement {
    toggleClass: (this: HTMLElement, event: MouseEvent) => void;
  }
}

export default {
  bind: function (el: HTMLElement, binding: DirectiveBinding): void {
    const targetId = binding.value.targetId || '';
    const classList = binding.value.class ? binding.value.class.split(' ') : false;

    // Setup toggle class functionality
    el.toggleClass = function (this: HTMLElement) {
      const target: HTMLElement|null = document.getElementById(targetId);

      if (target && classList) {
        classList.forEach((item: string) => {
          target.classList.toggle(item);
        });
      }
    };

    // Attach the click event to the element
    el.addEventListener('click',  el.toggleClass);
  },
  unbind: function (el: HTMLElement): void {
    el.removeEventListener('click', el.toggleClass);
  },
};
