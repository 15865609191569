import Address, {AddressJson} from "@/api/model/stripe/Address";
import {Currency} from "@/api/model/stripe/Currency";
import {ShippingJson} from "@/api/model/stripe/Shipping";
import {CustomFieldJson} from "@/api/model/stripe/CustomField";
import {ListJson} from "@/api/model/stripe/List";
import TaxId, {TaxIdJson} from "@/api/model/stripe/TaxId";


interface InvoiceSettingsJson {
  custom_fields: CustomFieldJson[] | null;
  default_payment_method: string | null;
  footer: string | null;
}

class InvoiceSettings {
  custom_fields: CustomFieldJson[] | null = null;
  default_payment_method: string | null = null;
  footer: string | null = null;

  static fromJSON(json: InvoiceSettingsJson): InvoiceSettings {
    //console.debug("InvoiceSettings.fromJSON", json);
    const response = Object.create(InvoiceSettings.prototype);
    return Object.assign(response, json, {
    });
  }
}

export interface CustomerJson {
  id: string;
  object:"customer";
  address: AddressJson | null;
  balance: number;
  created: number;
  currency: Currency;
  default_source: string;
  delinquent: boolean;
  description: string;
  discount: null;
  email: string;
  invoice_prefix: string;
  invoice_settings: InvoiceSettingsJson;
  livemode: boolean;
  metadata: Record<string, unknown>;
  name: string;
  phone: string;
  preferred_locales: string[];
  shipping: ShippingJson;
  tax_exempt: "none" | "exempt" | "reverse";
  tax_ids: ListJson<TaxIdJson>;
}

export default class Customer {
  id = 'unknown';
  address: Address | null = null;
  default_source = '';
  description = 'unknown';
  invoice_settings: InvoiceSettings | null = null;
  name = 'unknown';
  tax_ids = [] as TaxId[];

  static fromJSON(json: CustomerJson): Customer {
    //console.debug("Customer.fromJSON", json);
    const response = Object.create(Customer.prototype);
    return Object.assign(response, json, {
      address: json.address ? Address.fromJSON(json.address) : null,
      tax_ids: json.tax_ids ? json.tax_ids.data.map(taxId => TaxId.fromJSON(taxId)) : [],
      invoice_settings: json.invoice_settings ? InvoiceSettings.fromJSON(json.invoice_settings) : null,
    });
  }
}
