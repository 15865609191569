import axios, {AxiosResponse} from "axios";
import {HumanizeDuration, HumanizeDurationLanguage} from 'humanize-duration-ts';
import {DateTime, Duration} from "luxon";

interface PayloadJson {
  message: string;
  icon: string;
  href: string;
  type: string;
  id: string;
}

interface NotificationJson {
  created_at: string;
  data: PayloadJson;
  id: string;
  notifiable_id: number;
  notifiable_type: string;
  read_at: string;
  type: string;
  updated_at: string;
}

export class Payload {
  message = '';
  icon = '';
  href = '';
  type = '';
  id = '';

  static fromJSON(json: PayloadJson): Payload {
    //console.debug("Payload.fromJSON", json);
    const notification = Object.create(Payload.prototype);
    return Object.assign(notification, json, {
    });
  }

}

export class Notification {
  created_at: DateTime | null = null;
  data: Payload = new Payload();
  id = '';
  notifiable_id = 0;
  notifiable_type = '';
  read_at: DateTime | null = null;
  type = '';
  updated_at: DateTime | null = null;

  static langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
  static humanizer: HumanizeDuration = new HumanizeDuration(Notification.langService);

  static fromJSON(json: NotificationJson): Notification {
    //console.debug("Notification.fromJSON", json);
    const notification = Object.create(Notification.prototype);
    return Object.assign(notification, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
      read_at: json.read_at ? DateTime.fromISO(json.read_at) : null,
      data: Payload.fromJSON(json.data),
    });
  }

  static fromPayload(payload: Payload): Notification {
    const notification = new Notification();
    notification.data = payload;
    notification.id = payload.id;
    notification.type = payload.type;
    notification.created_at = DateTime.local();
    notification.updated_at = notification.created_at;
    return notification;
  }

  get date(): string {
    const duration: Duration = this.created_at?.diffNow() ?? DateTime.local().diffNow();
    return Notification.humanizer.humanize(duration.valueOf(), {largest: 1}) + " ago";
  }
}

export default class NotificationService {
  public static async list(): Promise<Notification[]> {
    return axios.get<NotificationJson[]>(`/api/notifications/`)
      .then((resp) => resp.data.map(json => Notification.fromJSON(json)));
  }

  public static delete(notification: Notification): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/notifications/${notification.id}`);
  }

}
