import axios, {AxiosResponse} from "axios";
import {WooSite} from "@/api/model/WooSite";

export class GoogleAnalyticsProperty {
  id = 0;
  accountId = 0;
  name = '';
  websiteUrl = '';
  created: Date | null = null;
  updated: Date | null = null;

  static fromJSON(json: GoogleAnalyticsPropertyJson): GoogleAnalyticsProperty {
    const response = Object.create(GoogleAnalyticsProperty.prototype);
    return Object.assign(response, json, {
      created: json.created ? new Date(json.created) : null,
      updated: json.updated ? new Date(json.updated) : null,
    });
  }
}

export class GoogleAccount {
  name = '';
  id = 0;
  picture = '';
  email = '';
  createdAt: Date|null = null;
  updatedAt: Date|null = null;
  organisation_id: number|null = null;
  static fromJSON(json: GoogleAccountJson): GoogleAccount {
    const response = Object.create(GoogleAccount.prototype);
    return Object.assign(response, json, {
      createdAt: json.created_at ? new Date(json.created_at) : null,
      updatedAt: json.updated_at ? new Date(json.updated_at) : null,
    });
  }
}

interface GoogleAccountJson {
  name: string;
  id: number;
  created_at: string;
  updated_at: string;
  picture: string;
  email: string;
  user_id: number;
  organisation_id: number;
}

interface GoogleAnalyticsPropertyJson {
  id: number;
  accountId: number;
  name: string;
  websiteUrl: string;
  created: string;
  updated: string;
}

export class GoogleRedirect {
  redirect = '';
  static fromJSON(json: GoogleRedirectJson): GoogleRedirect {
    const response = Object.create(GoogleRedirect.prototype);
    return Object.assign(response, json, {
    });
  }
}

export class GAProperty {
  id: number | null = null;

  created_at: Date | null = null;
  updated_at: Date | null = null;

  account_id: string | null = null;
  property_id: string | null = null;
  name: string | null = null;
  url: string | null = null;
  ga_created_at: Date | null = null;

  google_account: GoogleAccount | null = null;
  google_account_id: number | null = null;
  organisation_id: number | null = null;
  woo_site_id: number | null = null;

  static fromJSON(json: GAPropertyJson): GAProperty {
    const response = Object.create(GAProperty.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? new Date(json.created_at) : null,
      updated_at: json.updated_at ? new Date(json.updated_at) : null,
      ga_created_at: json.ga_created_at ? new Date(json.ga_created_at) : null,
      google_account: json.google_account ? GoogleAccount.fromJSON(json.google_account) : null,
    });
  }
}

export interface GAPropertyJson {
  id: number | null;

  created_at: string | null;
  updated_at: Date | null;

  account_id: string | null;
  property_id: string | null;
  name: string | null;
  url: string | null;
  ga_created_at: string | null;

  google_account: GoogleAccountJson | null;
  google_account_id: number | null;
  organisation_id: number | null;
  woo_site_id: number | null;
}

interface GoogleRedirectJson {
  redirect: string;
}

interface GoogleAccountListJson {
  data: GoogleAccountJson[];
}

interface GoogleAnalyticsPropertyListJson {
  data: GoogleAnalyticsPropertyJson[];
}

export default class GoogleAccountService {

  static list(): Promise<GoogleAccount[]> {
    return axios.get<GoogleAccountListJson>('/api/google/accounts' )
      .then((resp) => resp.data.data.map(data => GoogleAccount.fromJSON(data)));
  }

  static redirect(redirectUrl: string): Promise<GoogleRedirect> {
    return axios.get<GoogleRedirectJson>('/api/google/redirect', { params: {redirect: redirectUrl }} )
      .then((resp) => GoogleRedirect.fromJSON(resp.data));
  }

  static delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/google/accounts/${id}`);
  }

  static availableProperties(account: GoogleAccount): Promise<GoogleAnalyticsProperty[]> {
    return this.availablePropertiesById(account.id);
  }

  static availablePropertiesById(accountId: number): Promise<GoogleAnalyticsProperty[]> {
    return axios.get<GoogleAnalyticsPropertyListJson>(`/api/google/accounts/${accountId}/available_properties`)
      .then(resp => resp.data.data.map(json => GoogleAnalyticsProperty.fromJSON(json)));
  }

  static setPropertyForSite(accountId: number, site: WooSite, property: GoogleAnalyticsProperty): Promise<AxiosResponse<void>> {
    return axios.post<void>(`/api/google/accounts/${accountId}/link`, {
      site_id: site.id,
      account_id: property.accountId,
      property_id: property.id,
    });
  }

  static unlink(accountId: number, site: WooSite): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/google/accounts/${accountId}/site/${site.id}`);
  }
}
