import axios, {AxiosResponse} from "axios";

import {Resource} from "@/api/model/Resource";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import PageOptions from "@/api/model/PageOptions";
import Organisation from "@/api/model/Organisation";
import Domain, {DomainFactory, DomainJson} from "@/api/model/Domain";

interface DomainValidationResponseJson {
  validMX:	boolean;
  validSPF: boolean;
  validDKIM: boolean;
  spf_warnings: string[];
  spf_record: string;
  dkim_cname_record: string;
  dkim_txt_record: string;
}

export class DomainValidationResponse {
  validMX =	false;
  validSPF = false;
  validDKIM = false;
  spf_warnings: string[] = [];
  spf_record = '';
  dkim_cname_record = '';
  dkim_txt_record = '';


  static fromJSON(json: DomainValidationResponseJson): DomainValidationResponse {
    //console.debug("DomainValidationResponse.fromJSON", json);
    const response = Object.create(DomainValidationResponse.prototype);
    return Object.assign(response, json, {
    });
  }
}

export default class DomainService {
  public async list(orgId: number | null, pageOptions: PageOptions | null = null): Promise<PaginatedResponse<Domain>> {
    if (orgId === null) {
      return Promise.resolve(new PaginatedResponse());
    }
    return axios.get<PaginatedResponseJson<DomainJson>>(`/api/organisations/${orgId}/domains?${pageOptions?.query() ?? ''}`)
      .then((resp) =>PaginatedResponse.fromJSON(resp.data, new DomainFactory));
  }

  public async create(org: Organisation, domain: string): Promise<Domain> {
    if (org.id === null) {
      throw "Invalid site id";
    }
    return this.createByOrgId(org.id, domain);
  }

  public async createByOrgId(orgId: number, domain: string): Promise<Domain> {
    return axios.post<Resource<DomainJson>>(`/api/organisations/${orgId}/domains`, {domain: domain})
      .then((resp) => Domain.fromJSON(resp.data.data));
  }

  public async get(id: number): Promise<Domain> {
    return axios.get<Resource<DomainJson>>(`/api/domains/${id}`)
      .then((resp) => Domain.fromJSON(resp.data.data));
  }

  public async delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/domains/${id}`);
  }

  public async validate(domain: Domain): Promise<DomainValidationResponse> {
    return axios.get<DomainValidationResponseJson>(`/api/domains/${domain.id}/validate`)
      .then((response: AxiosResponse<DomainValidationResponseJson>) => DomainValidationResponse.fromJSON(response.data));
  }
}
