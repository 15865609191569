
































import Vue, {VueConstructor} from "vue";
import User from "@/api/model/User";
import {mapGetters} from "vuex";


interface VuexBindings {
  currentUser: User;
}

const userDropdown = (Vue as VueConstructor<Vue & VuexBindings>).extend({
    computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
});

export default userDropdown;
