import {WooOrderItem, WooOrderItemJson} from "@/api/model/WooOrderItem";
import {WooCustomer, WooCustomerJson} from "@/api/model/WooCustomer";
import {WooAddress} from "@/api/model/WooAddress";
import {FromJsonFactory} from "@/api/model/PaginatedResponse";
import {DateTime} from "luxon";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";
import "@/lib";

interface WooOrderCouponJson {
  id: number;
  created_at: string | null;
  updated_at: string | null;
  code: string;
  woo_id: number;
  discount: number;
  discount_tax: number;
  woo_order_id: number;
}

export interface WooOrderJson {
  id: number;
  woo_id: number;
  parent_id: number | null;
  woo_num: string | null;
  woo_key: string | null;
  total: number;
  refund: number;
  shopping_total: number;
  discount_total: number;
  discount_tax: number;
  shipping_tax: number;
  cart_tax: number;
  total_tax: number;
  prices_include_tax: number;
  currency: string;
  currency_symbol: string;
  exchange_rate: number;
  status: string;
  customer: WooCustomerJson | null;
  woo_customer_id: number;
  payment_method: string | null;
  date_created: string;
  date_completed: string | null;
  date_modified: string | null;
  date_paid: string | null;
  date_created_gmt: string;
  date_completed_gmt: string | null;
  date_modified_gmt: string | null;
  date_paid_gmt: string | null;
  subscription_id: number | null;
  subscription_order_type: 'parent_order' | 'renewal_order' | null;
  created_at: string | null;
  updated_at: string | null;
  organisation_id: number;
  woo_site_id: number;
  items: WooOrderItemJson[];
  billingAddress: WooAddress;
  shippingAddress: WooAddress;
  woo_site: WooSiteJson;
  coupons: WooOrderCouponJson[];
}

class WooOrderCoupon {
  id = 0;
  created_at: DateTime | null = null;
  updated_at: DateTime | null = null;
  code = '';
  woo_id = 0;
  discount = 0;
  discount_tax = 0;
  woo_order_id = 0;
  static fromJSON(json: WooOrderCouponJson): WooOrderCoupon {
    //console.debug("WooOrder.fromJSON()", json);
    const response = Object.create(WooOrderCoupon.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? new Date(json.created_at) : null,
      updated_at: json.updated_at ? new Date(json.updated_at) : null,
    });
  }
}

export class WooOrder {
  woo_key = '';
  woo_site_id = 0;
  organisation_id = 0;
  woo_num = '';
  parent_id = '';
  date_completed: DateTime | null = null;
  date_modified = DateTime.local();
  payment_method = '';
  created_via = '';
  version = '';
  date_created_gmt = DateTime.local();
  date_modified_gmt = DateTime.local();
  customer_ip_address = '0.0.0.0';
  customer_user_agent = '';
  customer_note = '';
  payment_method_title = '';
  transaction_id = '';
  date_paid: DateTime | null = null;
  date_paid_gmt: DateTime | null = null;
  date_completed_gmt: DateTime | null = null;
  cart_hash = '';

    id = 0;
    woo_id = '';
    total = 0;
    shipping_total = 0;
    refund = 0;
    status = '';

  discount_total = 0;
  discount_tax = 0;
  shipping_tax = 0;
  cart_tax = 0;
  total_tax = 0;
  prices_include_tax = false;
  currency = 'USD';
  exchange_rate = 1;

    currency_symbol = '$';
  customer: WooCustomer = new WooCustomer();
    woo_customer_id = 0;
    date_created: DateTime | null = null;
    items: WooOrderItem[] = [];
    billingAddress: WooAddress | null = null;
    shippingAddress: WooAddress | null = null;
  woo_site: WooSite = WooSite.allSite('USD');
  coupons: WooOrderCoupon[] = [];

    static fromJSON(json: WooOrderJson): WooOrder {
        //console.debug("WooOrder.fromJSON()", json);
        const response = Object.create(WooOrder.prototype);
        return Object.assign(response, json, {
            created_at: json.created_at ? new Date(json.created_at) : null,
            updated_at: json.updated_at ? new Date(json.updated_at) : null,
          date_created: json.date_created ? DateTime.fromISO(json.date_created) : null,
          date_created_gmt: json.date_created_gmt ? DateTime.fromISO(json.date_created_gmt, { zone: "UTC" }) : null,
          date_modified: json.date_modified ? DateTime.fromISO(json.date_modified) : null,
          date_modified_gmt: json.date_modified_gmt ? DateTime.fromISO(json.date_modified_gmt, { zone: "UTC" }) : null,
          date_completed: json.date_completed ? DateTime.fromISO(json.date_completed) : null,
          date_completed_gmt: json.date_completed_gmt ? DateTime.fromISO(json.date_completed_gmt, { zone: "UTC" }) : null,
          date_paid: json.date_paid ? DateTime.fromISO(json.date_paid) : null,
          date_paid_gmt: json.date_paid_gmt ? DateTime.fromISO(json.date_paid_gmt, { zone: "UTC" }) : null,
            customer: json.customer ? WooCustomer.fromJSON(json.customer) : null,
            items: json.items ? json.items.map(item => WooOrderItem.fromJSON(item)) : [],
            billingAddress: json.billingAddress ? WooAddress.fromJSON(json.billingAddress) : null,
            shippingAddress: json.shippingAddress ? WooAddress.fromJSON(json.shippingAddress) : null,
          woo_site: json.woo_site ? WooSite.fromJSON(json.woo_site) : WooSite.allSite('USD'),
          coupons: json.coupons ? json.coupons.map(json => WooOrderCoupon.fromJSON(json)) : [],
        });
    }

    get subtotal(): number {
      return this.items
      .map(item => +item.subtotal)
      .sum();
    }

  get costOfGoods(): number {
    return this.items
      .map(item => +item.cog_item_total_cost)
      .sum();
  }
  get quantity(): number {
    return this.items
      .map(item => +item.quantity)
      .sum();
  }

  get isGuestCheckout(): boolean {
      return this.customer && this.customer.isGuestCheckout;
  }
}

export class WooOrderFactory implements  FromJsonFactory<WooOrder, WooOrderJson> {
  fromJSON(json: WooOrderJson): WooOrder {
    return WooOrder.fromJSON(json);
  }

}
