import {DateTime} from "luxon";
import {AddressJson} from "@/api/model/stripe/Address";

interface BillingDetailsJson {
  address: AddressJson;
  email: string | null;
  name: string | null;
  phone: string | null;
}

type CheckResult = "pass" | "fail" | "unavailable" | "unchecked";

type CardBrand = "amex" | "diners" | "discover" | "jcb" | "mastercard" | "unionpay" | "visa" | "unknown";

interface CardJson {
  "brand": CardBrand;
  "checks": {
    "address_line1_check": CheckResult | null;
    "address_postal_code_check": CheckResult | null,
    "cvc_check": CheckResult | null,
  },
  "country": string,
  "exp_month": number,
  "exp_year": number,
  "fingerprint": string,
  "funding": "credit" | "debit" | "prepaid" | "unknown";
  "generated_from": null,
  "last4": string,
  "networks": {
    "available": string[],
    "preferred": string | null,
  },
  "three_d_secure_usage": {
    "supported": boolean,
  },
  "wallet": null,
}
export class Card {
  brand: CardBrand = "unknown";
  exp_month = 0;
  exp_year = 0;
  last4 = '0000';

  static fromJSON(json: CardJson): Card {
    //console.debug("Card.fromJSON", json);
    const response = Object.create(Card.prototype);
    return Object.assign(response, json, {

  });
  }
}


type PaymentMethodType = "acss_debit" |
  "afterpay_clearpay" |
  "alipay" |
  "au_becs_debit" |
  "bacs_debit" |
  "bancontact" |
  "boleto" |
  "card" |
  "card_present" |
  "eps" |
  "fpx" |
  "giropay" |
  "grabpay" |
  "ideal" |
  "interac_present" |
  "oxxo" |
  "p24" |
  "sepa_debit" |
  "sofort" |
  "wechat_pay";

export interface PaymentMethodJson {
  id: string;
  object: "payment_method";
  billing_details: BillingDetailsJson;
  card: CardJson;
  created: number;
  customer: string;
  livemode: boolean;
  metadata: Record<string, unknown>;
  type: PaymentMethodType;
}

export default class PaymentMethod {
  id = 'unknown';
  object: "payment_method" = "payment_method";
  card: Card | null = null;
  created = DateTime.local();

   static fromJSON(json: PaymentMethodJson): PaymentMethod {
    //console.debug("PaymentMethod.fromJSON", json);
    const response = Object.create(PaymentMethod.prototype);
    return Object.assign(response, json, {
      created: json.created ? DateTime.fromSeconds(json.created) : DateTime.local(),
      card: json.type === 'card' ? Card.fromJSON(json.card) : null,
    });
  }
}
