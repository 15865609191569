






















import Vue from "vue";
import User from "@/api/model/User";

const NoStripeSubscriptionWarning = Vue.extend({
  name: "NoStripeSubscriptionWarning",
  computed: {
    showNoSubscriptionWarning(): boolean {
      return this.currentUser?.current_organisation?.billing_country?.length === 0;
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
  },
});

export default NoStripeSubscriptionWarning;
