












import Vue from "vue";


const HelpHeaderButton = Vue.extend({
  name: "HelpHeaderButton",
  data: () => ({
    helpRoute : "Help",
  }),

  methods:{
    pickRoute():void{
      const routesNames = ["Sites Add","Sites Integrations","User Settings","Dashboard","Orders","Customers","Products","Coupons","Sales By Day",
      "Org Settings Domains","Email Sequence List","Campaigns List","CustomerSegments"];
      if(routesNames.includes(this.$route.name ?? '')){
        this.helpRoute = this.$route.name + " Help";
      }
    },
  },
  created():void{
    this.pickRoute();
  },
  watch:{
    $route (){
      this.pickRoute();
    },
  },

});

export default HelpHeaderButton;
