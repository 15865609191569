

















import Component from "vue-class-component";
import {Vue, Watch} from "vue-property-decorator";
import {Getter} from 'vuex-class';
import {WooSite} from "@/api/model/WooSite";
import {DateTime, Interval} from "luxon";

@Component({})
export default class NewSiteAlert extends Vue {
  @Getter('allSites') allSites!: Array<WooSite>;

  private showImportInProgressWarning = false;

  @Watch('allSites')
  private checkSiteImporting() {
    this.allSites.filter(site => site.id !== 'all'
    ) // i.e. not "all" sites is selected
    .some((site) => {

      const i = Interval.fromDateTimes(site.created_at, DateTime.local());

      const diffMinutes = i.length('minutes').toFixed(0);

      if(Number(diffMinutes) < 60) {
        this.showImportInProgressWarning = true;
        return;
      }

      this.showImportInProgressWarning = false;
    });
  }

}
