import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import User from "@/api/model/User";
import {default as authService, LoginResponse} from "@/api/AuthService";
import LaravelError from "@/api/model/LaravelError";
import {RootState} from "@/store/RootState";
import Register from "@/api/model/Register";
import Vue from "vue";

interface AuthState {
  user: User | null;
  isLoggedIn: boolean;
}

function getDefaultState(): AuthState {
  //console.debug("Auth getDefaultState()", localStorage);
  return {
    user: null,
    isLoggedIn: localStorage.getItem("logged_in") === "true",
  };
}

function registerProfitWell(user: User) {
  if (Vue.gtm.enabled()) {
    Vue.gtm.trackEvent({
      'event': 'start_pw',
      'pw_user_email': user.email,
    });
  }
  return user;
}

const state: AuthState = getDefaultState();

const getters: GetterTree<AuthState, RootState> = {
  loggedIn(state): boolean {
    //console.debug("get loggedIn", state);
    return state.isLoggedIn;
  },
  currentUser(state): User {
    //console.debug("get currentUser", state);
    return state.user ?? new User();
  },
};

const actions: ActionTree<AuthState, RootState> = {
  register(context: ActionContext<AuthState, RootState>, user: Register): Promise<User> {
    //console.debug("dispatch register", user);
      return authService.register(user)
        .then(user => {
          context.commit('setUser', user);
          return user;
        });
  },
  login(context: ActionContext<AuthState, RootState>, credentials: {username: string; password: string}) {
    //console.debug("dispatch login", credentials);
    return new Promise((resolve, reject) => {
      authService.login(credentials.username, credentials.password, true)
        .then((response: LoginResponse) => {
          context.commit('setUser', response.user);
          registerProfitWell(response.user);
          resolve(response);
        })
        .catch((error: LaravelError) => {
          //console.log(error)
          reject(error);
        });
    });
  },
  fetchCurrentUser(context: ActionContext<AuthState, RootState>) {
    //console.debug("dispatch fetchCurrentUser", context);
    if (!context.state.isLoggedIn) {
      return;
    }
    return authService.currentUser()
      .then(registerProfitWell)
      .then(user => context.commit('setUser', user));
  },
  logout(context: ActionContext<AuthState, RootState>) {
    //console.debug("dispatch logout");
    if (!context.state.isLoggedIn) {
      return;
    }

    return new Promise((resolve, reject) => {
      authService.logout()
        .then(response => {
          context.commit('setUser', null);
          resolve(response);
        })
        .catch(error => {
          context.commit('setUser', null);
          reject(error);
        })
        .finally(() => context.dispatch('resetSitesState'));
    });
  },
};

const mutations: MutationTree<AuthState> = {
  setUser(state, user: User) {
    //console.debug("commit setUser", state, user);
    state.isLoggedIn = user !== null;
    localStorage.setItem("logged_in", String(state.isLoggedIn));
    state.user = user;
    //console.debug("current state:", state, localStorage)
  },
};

const namespaced = false;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
