import {WooSite} from "@/api/model/WooSite";
import PageOptions from "@/api/model/PageOptions";
import {FromJsonFactory, PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import axios from "axios";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import {WooCustomer, WooCustomerJson} from "@/api/model/WooCustomer";
import {DateTime} from "luxon";
import {Resource} from "@/api/model/Resource";
import {WooCustomerEmail, WooCustomerEmailJson} from "@/api/model/WooCustomerEmail";


export class WooCustomerSummary {
  id =  0;
  woo_id = 0;
  first_name = '';
  last_name = '';
  username = '';
  created_at: DateTime | null = null;
  updated_at: DateTime | null = null;
  address_1 = '';
  country = '';
  woo_site_id = 0;
  is_imported = true;
  state = '';
  address_2 = '';
  company = '';
  postcode = '';
  email = '';
  city = '';
  phone = '';
  date_created: DateTime | null = null;
  date_created_gmt: DateTime | null = null;
  date_modified: DateTime | null = null;
  date_modified_gmt: DateTime | null = null;
  role = '';
  is_paying_customer = false;
  avatar_url = '';
  order_count = 0;
  total_spend = 0;
  aov = 0;
  last_active: DateTime | null = null;

  static fromJSON(json: WooCustomerSummaryJson): WooCustomerSummary {
    //console.debug("WooCustomerSummary.fromJSON", json, DateTime.fromISO(json.last_active).toLocaleString(DateTime.DATETIME_FULL));
    const response = Object.create(WooCustomerSummary.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? new Date(json.created_at) : null,
      updated_at: json.updated_at ? new Date(json.updated_at) : null,
      date_created: json.date_created ? DateTime.fromISO(json.date_created) : null,
      date_created_gmt: json.date_created_gmt ? DateTime.fromISO(json.date_created_gmt) : null,
      date_modified: json.date_modified ? DateTime.fromISO(json.date_modified) : null,
      date_modified_gmt: json.date_modified_gmt ? DateTime.fromISO(json.date_modified_gmt) : null,
      last_active: json.last_active ? DateTime.fromISO(json.last_active) : null,
    });
  }
}

interface WooCustomerSummaryJson {
  created_at: string;
  updated_at: string;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  last_active: string;
}

class WooCustomerStatisticsFactory implements FromJsonFactory<WooCustomerSummary, WooCustomerSummaryJson>{
  fromJSON(json: WooCustomerSummaryJson): WooCustomerSummary {
    return WooCustomerSummary.fromJSON(json);
  }
}

export default class WooCustomerService {
  public async summary(siteId: number|string, pageOptions: PageOptions): Promise<PaginatedResponse<WooCustomerSummary>> {
    return axios.get<PaginatedResponseJson<WooCustomerSummaryJson>>(`/api/sites/${siteId}/customers/summary?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooCustomerStatisticsFactory));
  }

  public static async list(site: WooSite): Promise<WooCustomer[]> {
    return axios.get<ResourceCollection<WooCustomerJson>>(`/api/sites/${site.id}/coupons`)
      .then((resp) => resp.data.data.map(json => WooCustomer.fromJSON(json)));
  }

  public static async get(id: number): Promise<WooCustomer> {
    return axios.get<Resource<WooCustomerJson>>(`/api/customers/${id}`)
      .then((resp) => WooCustomer.fromJSON(resp.data.data));
  }

  public async sentEmails(customerId: number): Promise<WooCustomerEmail[]> {
    return axios.get<ResourceCollection<WooCustomerEmailJson>>(`/api/customers/${customerId}/emails`)
      .then((resp) => resp.data.data.map((json: WooCustomerEmailJson) => WooCustomerEmail.fromJSON(json)));
  }
}
