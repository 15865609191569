import {WooProduct, WooProductJson} from "@/api/model/WooProduct";

export interface CartItemJson {
  id: number;
  key: string,
  product: WooProductJson | null;
  quantity: 	number;
  subtotal: 	number;
  subtotal_tax: 	number;
  total: 	number;
  tax: 	number;
}

export class CartItem {
  id = '';
  key = '';
  product: WooProduct | null = null;
  quantity = 1;
  subtotal = 0;
  subtotal_tax = 0;
  total = 0;
  tax = 0;

  get discount(): number {
    if (this.total >= this.subtotal) {
      return 0;
    }
    return this.subtotal - this.total;
  }

    static fromJSON(json: CartItemJson): CartItem {
        //console.debug("CartItem.fromJSON()", json);
        const response = Object.create(CartItem.prototype);
        return Object.assign(response, json, {
            product: json.product ? WooProduct.fromJSON(json.product) : null,
        });
    }
}
