import {FromJsonFactory} from "@/api/model/PaginatedResponse";
import {DateTime} from "luxon";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";

class WooProductImage {
  id = 0;
  name = '';
  alt = '';
  src = '';
  main_image = false;
  date_created: DateTime | null = null;
  date_modified: DateTime | null = null;
  created_at: DateTime | null = null;
  updated_at: DateTime | null = null;
  woo_product_id = 0;
  format = 'image/png';
  height = 0;
  width = 0;

  static fromJSON(json: WooProductImageJson): WooProductImage {
    const response = Object.create(WooProductImage.prototype);
    return Object.assign(response, json, {
      date_created: json.date_created ? DateTime.fromISO(json.date_created) : null,
      date_modified: json.date_modified ? DateTime.fromISO(json.date_modified) : null,
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
    });
  }
}

interface WooProductImageJson {
  id: number;
  name: string;
  alt: string;
  src: string;
  main_image: boolean;
  date_created: string;
  date_modified: string;
  created_at: string;
  updated_at: string;
  woo_product_id: number;
  format: string;
  height: number;
  width: number;
}

export class WooProduct {
    id = 0;
    woo_id: number | null = null;
    name: string | null = null;
    slug: string | null = null;
    permalink: string | null = null;
    date_created: Date | null = null;
    date_modified: Date | null = null;
    type: string | null = null;
    status: string | null = null;
    featured = false;
    short_description: string | null = null;
    sku: string | null = null;
    price: number | null = null;
    regular_price: number | null = null;
    stock_quantity: number | null = null;
    parent_id: number | null = null;
    created_at: Date | null = null;
    updated_at: Date | null = null;
    organisation_id: number | null = null;
    woo_site_id: number | null = null;
    cost_of_goods: number | null = null;
    orders: string | null = null;
    revenue: number | null = null;
    hidden = false;
    images: WooProductImage[] = [];
    custom_name: string | null = null;
    site: WooSite | null = null;
    stats: Record<string, number> = {};

    static fromJSON(json: WooProductJson): WooProduct {
      //console.log('WooProduct.fromJSON()', json);
      const response = Object.create(WooProduct.prototype);
        return Object.assign(response, json, {
            date_created: json.date_created ? new Date(json.date_created) : null,
            date_modified: json.date_modified ? new Date(json.date_modified) : null,
            created_at: json.created_at ? new Date(json.created_at) : null,
            updated_at: json.updated_at ? new Date(json.updated_at) : null,
          images: json.images ? json.images.map(image => WooProductImage.fromJSON(image)) : [],
          site: json.site ? WooSite.fromJSON(json.site) : null,
        });
    }

    get mainImage(): WooProductImage {
      return this.images.find(image => image.main_image) ?? this.images[0];
    }

    get mainImageSrc(): string {
      return this.mainImage?.src ?? '';
    }

  displayName(): string {
    return this.custom_name ?? this.name ?? 'Unknown';
  }

}

export interface WooProductJson {
    id: number | null;
    woo_id: number | null;
    name: string | null;
    slug: string | null;
    permalink: string | null;
    date_created: Date | null;
    date_modified: Date | null;
    type: string | null;
    status: string | null;
    featured: boolean;
    short_description: string | null;
    sku: string | null;
    price: number | null;
    regular_price: number | null;
    stock_quantity: number | null;
    parent_id: number | null;
    created_at: Date | null;
    updated_at: Date | null;
    organisation_id: number | null;
    woo_site_id: number | null;
    cost_of_goods: number | null;
    hidden: boolean;
    images: WooProductImageJson[];
    custom_name: string | null;
    site: WooSiteJson | null;
}

export class WooProductFactory implements FromJsonFactory<WooProduct, WooProductJson> {
    fromJSON(json: WooProductJson): WooProduct {
        return WooProduct.fromJSON(json);
    }
}
