
import axios from "axios";
import {DateRange} from "@/api/model/DateRange";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import {EmailSequence} from "@/api/model/EmailSequence";



export interface UTMSourceStat {
  utm_source: string;
  count: number;
  revenue: number;
}

export interface KPIStat {
  current: number;
  previous: number;
}

export interface ConversionsBySequenceSourceStat {
  sequence: EmailSequence;
  conversions: number;
  conversionsRecipient: number;
  revenue: number;
}

export default class StatsService {

  public async utmSource(siteId: number | "all" | null, range: DateRange): Promise<UTMSourceStat[]> {
    if (!siteId) {
      return Promise.resolve([]);
    }

    return axios.get<UTMSourceStat[]>(`/api/sites/${siteId}/stats/utmsource?${range.getParams()}`)
      .then(response => response.data);
  }

  public async getKPIStat(stateName: string, siteId: number | "all" | null, range: DateRange) {
    if (!siteId) {
      return Promise.resolve({current: 0, previous: 0});
    }

    return axios.get<KPIStat>(`/api/sites/${siteId}/stats/${stateName}?${range.getParams()}`)
      .then(response => response.data);
  }

  public async conversionsBySequenceSource(siteId: number | "all" | null,  range: DateRange): Promise<ConversionsBySequenceSourceStat[]> {
    if (!siteId) {
      return Promise.resolve([]);
    }
    return axios.get<ResourceCollection<ConversionsBySequenceSourceStat>>(`/api/sites/${siteId}/stats/conversion_by_sequence?${range.getParams()}`)
      .then(response => response.data.data);
  }
}
