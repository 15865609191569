import {DateTime} from "luxon";
import {FromJsonFactory} from "@/api/model/PaginatedResponse";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";


export interface DomainJson {
  id: number;
  created_at: string;
  updated_at: string;
  domain: string;
  dkim_selector: string;
  dkim_public_key: string;
  validated: boolean;
  sites: WooSiteJson[];
}

export default class Domain {
  id = 0;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  domain = '';
  sites: WooSite[] = [];
  dkim_selector = '';
  dkim_public_key = '';
  validated = false;

  static fromJSON(json: DomainJson): Domain {
    //console.debug("Dashboard.fromJSON", json);
    const response = Object.create(Domain.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.local(),
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : DateTime.local(),
      sites: json.sites ? json.sites.map(json => WooSite.fromJSON(json)) : [],
    });
  }
}

export class DomainFactory implements  FromJsonFactory<Domain, DomainJson> {
  fromJSON(json: DomainJson): Domain {
    return Domain.fromJSON(json);
  }
}
