import axios from "axios";
import Invoice, {InvoiceJson} from "@/api/model/stripe/Invoice";
import Customer, {CustomerJson} from "@/api/model/stripe/Customer";
import PaymentMethod, {PaymentMethodJson} from "@/api/model/stripe/PaymentMethod";
import {SetupIntent, SetupIntentJson} from "@/api/model/stripe/SetupIntent";
import { SetupIntent as StripeSetupIntent } from  '@stripe/stripe-js';

interface CustomerAndMethodsJson {
  customer: CustomerJson;
  methods: PaymentMethodJson[];
}

export interface CustomerAndMethods {
  customer: Customer;
  methods: PaymentMethod[];
}

export default class InvoiceService {
  public async listByOrganisationId(organisationId: number): Promise<Invoice[]> {
    return axios.get<InvoiceJson[]>(`/api/organisations/${organisationId}/invoices`)
      .then((resp) => resp.data.map(json => Invoice.fromJSON(json)));
  }

  public async getDetailsByOrganisationId(organisationId: number): Promise<Customer> {
    return axios.get<CustomerJson>(`/api/organisations/${organisationId}/invoices/details`)
      .then((resp) =>  Customer.fromJSON(resp.data));
  }

  public async setDetailsByOrganisationId(organisationId: number, customer: Customer): Promise<Customer> {
    return axios.post<CustomerJson>(`/api/organisations/${organisationId}/invoices/details`, {
      'billing_name': customer.name,
      'billing_street1': customer.address?.line1,
      'billing_street2': customer.address?.line2,
      'billing_city': customer.address?.city,
      'billing_state': customer.address?.state,
      'billing_postcode': customer.address?.postal_code,
      'billing_country': customer.address?.country,
      'tax_id_type': customer.tax_ids[0].type,
      'tax_id': customer.tax_ids[0].value,
    })
      .then((resp) =>  Customer.fromJSON(resp.data));
  }

  public async getPaymentMethodsByOrganisationId(organisationId: number): Promise<PaymentMethod[]> {
    return axios.get<PaymentMethodJson[]>(`/api/organisations/${organisationId}/payment_methods`)
      .then((resp) =>  resp.data.map(json => PaymentMethod.fromJSON(json)));
  }

  public async getSetupIntentByOrganisationId(organisationId: number): Promise<SetupIntent> {
    return axios.get<SetupIntentJson>(`/api/organisations/${organisationId}/payment_methods/create`)
      .then((resp) =>  SetupIntent.fromJSON(resp.data));
  }

  public async addPaymentMethod(organisationId: number, setupIntent: StripeSetupIntent): Promise<CustomerAndMethods> {
    return axios.post<CustomerAndMethodsJson>(`/api/organisations/${organisationId}/payment_methods/`, setupIntent)
      .then((resp) => ({
        customer: Customer.fromJSON(resp.data.customer),
        methods: resp.data.methods.map(json => PaymentMethod.fromJSON(json)),
      }));
  }

  public async deletePaymentMethod(organisationId: number, method: PaymentMethod): Promise<CustomerAndMethods> {
    return axios.delete<CustomerAndMethodsJson>(`/api/organisations/${organisationId}/payment_methods/${method.id}`)
      .then((resp) => ({
        customer: Customer.fromJSON(resp.data.customer),
        methods: resp.data.methods.map(json => PaymentMethod.fromJSON(json)),
      }));
  }

  public async setDefaultPaymentMethod(organisationId: number, method: PaymentMethod): Promise<Customer> {
    return axios.patch<CustomerJson>(`/api/organisations/${organisationId}/payment_methods/`, method)
      .then((resp) =>  Customer.fromJSON(resp.data));
  }
}
