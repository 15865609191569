import { DateTime } from "luxon";
import { FromJsonFactory } from "./PaginatedResponse";
import {WooCustomer, WooCustomerJson} from "@/api/model/WooCustomer";
import {WooSubscriptionItem, WooSubscriptionItemJson} from "@/api/model/WooSubscriptionItem";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";

export interface WooSubscriptionJson {
  id: number;
  billing_period: string | null;
  billing_interval: string | null;
  created_at: string | null;
  updated_at: string | null;
  next_payment_date: string | null;
  end_date: string | null;
  last_renewal_date: string | null;
  customer: WooCustomerJson;
  items: WooSubscriptionItemJson[];
  site: WooSiteJson | null;
}

export class WooSubscription {
    id: number | null | "all" = null;
    total: number | null | "all" = null;
    billing_period: string | null = '';
    billing_interval: string | null = '';
    created_at = DateTime.local();
    updated_at = DateTime.local();
    next_payment_date: DateTime | null = null;
    end_date: DateTime | null = null;
    last_renewal_date: DateTime | null = null;
    customer: WooCustomer = new WooCustomer();
    items: WooSubscriptionItem[] = [];
    site: WooSite | null = null;

    static fromJSON(json: WooSubscriptionJson): WooSubscription {
        //console.debug("WooSubscription.fromJSON()", json);
        const response = Object.create(WooSubscription.prototype);
        return Object.assign(response, json, {
            customer: json.customer ? WooCustomer.fromJSON(json.customer) : null,
            created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.local(),
            updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : DateTime.local(),
            next_payment_date: json.next_payment_date ? DateTime.fromISO(json.next_payment_date) : null,
            end_date: json.end_date ? DateTime.fromISO(json.end_date) : null,
            last_renewal_date: json.last_renewal_date ? DateTime.fromISO(json.last_renewal_date) : null,
            items: json.items ? json.items.map(item => WooSubscriptionItem.fromJSON(item)) : [],
            site: json.site ? WooSite.fromJSON(json.site) : null,
        });
    }

}

export class WooSubscriptionFactory implements FromJsonFactory<WooSubscription, WooSubscriptionJson> {
    fromJSON(json: WooSubscriptionJson): WooSubscription {
        return WooSubscription.fromJSON(json);
    }
}
