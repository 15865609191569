import PageOptions from "@/api/model/PageOptions";
import {FromJsonFactory, PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import axios from "axios";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import {WooCoupon, WooCouponJson} from "@/api/model/WooCoupon";
import {DateTime} from "luxon";


export class WooCouponStatistics {
  code = '';
  description = '';
  discount = '';
  order_count = 0;
  usage_limit = 0;
  amount_discounted = 0;
  revenue_generated = 0;
  static fromJSON(json: WooCouponStatisticsJson): WooCouponStatistics {
    //console.debug("WooCouponStatistics.fromJSON", json);
    const response = Object.create(WooCouponStatistics.prototype);
    return Object.assign(response, json, {
    });
  }
}

interface WooCouponStatisticsJson {
  code: string;
  description: string;
  discount: string;
  order_count: number;
  usage_limit: number;
  amount_discounted: number;
  revenue_generated: number;
}

class WooCouponStatisticsFactory implements FromJsonFactory<WooCouponStatistics, WooCouponStatisticsJson>{
  fromJSON(json: WooCouponStatisticsJson): WooCouponStatistics {
    return WooCouponStatistics.fromJSON(json);
  }
}

export default class WooCouponService {
  public async getStatistics(siteId: number | string, from: DateTime, to: DateTime, pageOptions: PageOptions): Promise<PaginatedResponse<WooCouponStatistics>> {
    const fromStr = from.setZone('UTC', {keepLocalTime: true});
    const toStr = to.setZone('UTC', {keepLocalTime: true});
    return axios.get<PaginatedResponseJson<WooCouponStatisticsJson>>(`/api/sites/${siteId}/coupons/statistics?from=${fromStr}&to=${toStr}&${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooCouponStatisticsFactory));
  }

  public async list(siteId: number | string): Promise<WooCoupon[]> {
    return axios.get<ResourceCollection<WooCouponJson>>(`/api/sites/${siteId}/coupons`)
      .then((resp) => resp.data.data.map(json => WooCoupon.fromJSON(json)));
  }
}
