/*
 * Default Router
 */

// Vue and Vue Router
import Vue from 'vue';
import Router, {NavigationGuard, Route} from 'vue-router';
import * as Sentry from "@sentry/vue";

// Main layouts
import LayoutBackend from '@/layouts/variations/Backend.vue';
import LayoutDashboard from '@/layouts/variations/Dashboard.vue';
import LayoutSimple from '@/layouts/variations/Simple.vue';
import store from '@/store';

// Register Vue Router
Vue.use(Router);

// Backend: General
const Dashboard = () => import(/* webpackChunkName: "pages-dashboard", webpackPrefetch: true */"@/views/dashboard/Dashboard.vue");

//Blue Odin Pages

// Profile
const SitesList = () => import(/* webpackChunkName: "sites-list" */"@/views/profile/SitesList.vue");
const SitesAdd = () => import(/* webpackChunkName: "sites-add" */"@/views/profile/SitesAdd.vue");
const SitesEdit = () => import(/* webpackChunkName: "sites-edit" */"@/views/profile/SitesEdit.vue");
const SitesSettings = () => import(/* webpackChunkName: "sites-settings" */"@/views/profile/SitesSettings.vue");
const SitesIntegrations = () => import(/* webpackChunkName: "sites-integrations" */"@/views/profile/SitesIntegrations.vue");

const ProductsList = () => import(/* webpackChunkName: "products-list" */"@/views/products/ProductsList.vue");
const ProductsEdit = () => import(/* webpackChunkName: "products-edit" */"@/views/products/ProductsEdit.vue");

const UserSettings = () => import(/* webpackChunkName: "user-settings" */"@/views/profile/UserSettings.vue");
const GeneralSettings = () => import(/* webpackChunkName: "general-settings" */"@/views/profile/GeneralSettings.vue");
const InvoicesSettings = () => import(/* webpackChunkName: "invoice-settings" */"@/views/profile/organisation/invoices/InvoicesSettings.vue");
const BillingSettings = () => import(/* webpackChunkName: "billing-settings" */"@/views/profile/organisation/billing/BillingSettings.vue");
const UserSettingsGeneral = () => import(/* webpackChunkName: "user-settings-general" */"@/views/profile/UserSettingsGeneral.vue");
const UserSettingsSecurity = () => import(/* webpackChunkName: "user-settings-security" */"@/views/profile/UserSettingsSecurity.vue");
const OrgSettingsDomains = () => import(/* webpackChunkName: "org-settings-domains" */"@/views/profile/OrgSettingsDomains.vue");
const DeleteOrganisation = () => import(/* webpackChunkName: "delete-organisation" */"@/views/profile/organisation/DeleteOrganisation.vue");

// Pages: Auth
const AuthSignIn2 = () => import(/* webpackChunkName: "auth-signin2" */"@/views/pages/auth/SignIn2.vue");
const AuthSignUp2 = () => import(/* webpackChunkName: "auth-signup2" */"@/views/pages/auth/SignUp2.vue");
const AuthSignUpConfirm = () => import(/* webpackChunkName: "auth-signup2" */"@/views/pages/auth/SignUpConfirm.vue");
const AuthReminder = () => import(/* webpackChunkName: "auth-reminder" */"@/views/pages/auth/Reminder.vue");
const AuthReminder2 = () => import(/* webpackChunkName: "auth-reminder2" */"@/views/pages/auth/Reminder2.vue");
const AuthLogout = () => import(/* webpackChunkName: "auth-logout" */"@/views/pages/auth/Logout.vue");
const AuthEmailVerified = () => import(/* webpackChunkName: "auth-email-verified" */"@/views/pages/auth/EmailVerified.vue");
const AuthEmailVerify = () => import(/* webpackChunkName: "auth-email-verify" */"@/views/pages/auth/EmailVerify.vue");
const AuthResetPassword = () => import(/* webpackChunkName: "auth-reset-password" */"@/views/pages/auth/ResetPassword.vue");
const AuthResetPasswordConfirm = () => import(/* webpackChunkName: "auth-reset-password-confirm" */"@/views/pages/auth/ResetPasswordConfirm.vue");

//Abandoned Cart
const CartsList = () => import(/* webpackChunkName: "carts-list" */"@/views/carts/CartsList.vue");
const CartView = () => import(/* webpackChunkName: "carts-list" */"@/views/carts/CartView.vue");


// Customers
const CustomersList = () => import(/* webpackChunkName: "customers-list" */"@/views/customers/CustomersList.vue");
const CustomerView = () => import(/* webpackChunkName: "customer-view" */"@/views/customers/CustomerView.vue");

// Customer Segments
const CustomerSegmentsList = () => import(/* webpackChunkName: "customer-segments-list" */"@/views/segments/customer/CustomerSegmentList.vue");
const CustomerSegmentsEdit = () => import(/* webpackChunkName: "customer-segments-edit" */"@/views/segments/customer/CustomerSegmentEdit.vue");

// Email Sequence
const EmailSequenceList = () => import(/* webpackChunkName: "emails-sequence-list" */"@/views/emailsequence/EmailSequenceList.vue");
const EmailSequenceEdit = () => import(/* webpackChunkName: "emails-sequence-edit" */"@/views/emailsequence/EmailSequenceEdit.vue");

// Campaigns
const CampaignsList = () => import(/* webpackChunkName: "campaigns-list" */"@/views/campaigns/CampaignsList.vue");
const CampaignEdit = () => import(/* webpackChunkName: "campaign-edit" */"@/views/campaigns/CampaignEdit.vue");

// Orders
const OrdersList = () => import(/* webpackChunkName: "orders-list" */"@/views/orders/OrdersList.vue");
const OrderView = () => import(/* webpackChunkName: "orders-view" */"@/views/orders/OrderView.vue");

// Coupons
const CouponsList = () => import(/* webpackChunkName: "coupons-list" */"@/views/coupons/CouponsList.vue");

// Analytics
const AnalyticsList = () => import(/* webpackChunkName: "analytics-list" */"@/views/analytics/AnalyticsList.vue");


// Metrics
const SalesByDay = () => import(/* webpackChunkName: "pages-metrics-daily-sales", webpackPrefetch: true */"@/views/metrics/SalesByDay.vue");

// Subscriptions
const SubscriptionsList = () => import(/* webpackChunkName: "subscriptions-list", webpackPrefetch: true */"@/views/subscriptions/SubscriptionsList.vue");

const PagesWelcomeAboardSiteAdd = () => import(/* webpackChunkName: "welcome-sites-add" */"@/views/onboarding/WelcomeAboardSiteAdd.vue");
const PagesWelcomeAboardSiteEdit = () => import(/* webpackChunkName: "welcome-sites-edit" */"@/views/onboarding/WelcomeAboardSiteEdit.vue");
const PagesWelcomeAboardIntegrations = () => import(/* webpackChunkName: "welcome-sites-integrations" */"@/views/onboarding/WelcomeAboardIntegrations.vue");
const WelcomeAboardSurvey = () => import(/* webpackChunkName: "welcome-survey" */"@/views/onboarding/WelcomeAboardSurvey.vue");

// Pages: Errors
//const Errors400 = () => import("@/views/pages/errors/400.vue");
//const Errors401 = () => import("@/views/pages/errors/401.vue");
//const Errors403 = () => import("@/views/pages/errors/403.vue");
const Errors404 = () => import("@/views/pages/errors/404.vue");
//const Errors500 = () => import("@/views/pages/errors/500.vue");
//const Errors503 = () => import("@/views/pages/errors/503.vue");


// Router Configuration
const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: '',
  scrollBehavior: function(to) {
    if (to.hash) {
        return {selector: to.hash};
        //Or for Vue 3:
        //return {el: to.hash}
    } else {
        return { x: 0, y: 0 };
    }
},
  routes: [
    {
      path: '/',
      redirect: {name: 'OldDashboard'},
      component: LayoutSimple,
    },
    {
      path: '/welcome-aboard',
      redirect: { name: 'Welcome Aboard Step 1' },
      meta: {
        requiresAuth: true,
      },
      component: LayoutSimple,
      children: [
        {
          path: 'add-site',
          name: 'Welcome Aboard Step 1',
          component: PagesWelcomeAboardSiteAdd,
          meta: {
            title: "Add Site",
          },
        },
        {
          path: 'edit-site/:id',
          name: 'Welcome Aboard Step 2',
          component: PagesWelcomeAboardSiteEdit,
          props: true,
          //component: SitesEdit,
          meta: {
            title: "Confirm Site Info",
          },
        },
        {
          path: 'add-integrations/:id',
          name: 'Welcome Aboard Step 3',
          props: true,
          // component: SitesIntegrations
          component: PagesWelcomeAboardIntegrations,
          meta: {
            title: "Add Integrations",
          },
        },
        {
          path: 'survey',
          name: 'Welcome Aboard Survey',
          props: true,
          // component: SitesIntegrations
          component: WelcomeAboardSurvey,
          meta: {
            title: "Survey",
          },
        },
      ],
    },
    {
      path: '/auth',
      component: LayoutSimple,
      children: [
        {
          path: 'login',
          name: 'login',
          component: AuthSignIn2,
        },
        {
          path: 'signup',
          name: 'Sign Up',
          component: AuthSignUp2,
        },
        {
          path: 'signup/confirm',
          name: 'Sign Up Confirm',
          component: AuthSignUpConfirm,
        },
        {
          path: 'reminder',
          name: 'Auth Reminder',
          component: AuthReminder,
        },
        {
          path: 'reminder2',
          name: 'Auth Reminder 2',
          component: AuthReminder2,
        },
        {
          path: 'signup/email-verified',
          name: 'Email Verified',
          component: AuthEmailVerified,
        },

        {
          path: 'password/reset',
          name: 'Reset Password',
          component: AuthResetPassword,
        },
        {
          path: 'reset-password-confirm',
          name: 'Reset Password Confirm',
          component: AuthResetPasswordConfirm,
        },
        {
            path: 'logout',
            name: 'logout',
            component: AuthLogout,
            meta: {
                requiresAuth: true,
            },
        },
      ],
    },
    {
      path: '/email/verify/:id/:hash',
      name: 'Email Verify',
      component: AuthEmailVerify,
    },
    {
      path: '/dashboard/:id?',
      name: 'OldDashboard',
      redirect: (to: Route) => {
        return {
          name: 'Dashboard',
          params: {
            site_id: 'all',
            id: to.params.id ?? 'default',
          },
        };
      },
    },
    {
      path: '/profile',
      redirect: '/dashboard',
      component: LayoutBackend,
      children: [
        {
          path: 'sites',
          component: {
            render (c) { return c('router-view'); },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '',
              name: 'Sites',
              component: SitesList,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'add',
              name: 'Sites Add',
              component: SitesAdd,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: ':id',
              component: SitesSettings,
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: 'confirm',
                  name: 'Sites Confirm',
                  component: SitesEdit,
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: 'edit',
                  name: 'Sites Edit',
                  component: SitesEdit,
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: 'integrations',
                  name: 'Sites Integrations',
                  component: SitesIntegrations,
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'user',
          component: UserSettings,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: 'general',
              name: 'General Settings',
              component:GeneralSettings,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'user',
              name: 'User Settings',
              component: UserSettingsGeneral,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'billing',
              name: 'Billing Settings',
              component: BillingSettings,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'invoices',
              name: 'Invoices Settings',
              component: InvoicesSettings,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'security',
              name: 'User Settings Security',
              component: UserSettingsSecurity,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'domains',
              name: 'Org Settings Domains',
              component: OrgSettingsDomains,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'delete',
              name: 'Delete Account',
              component: DeleteOrganisation,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/customers',
      component: LayoutBackend,
      children: [

        {
          path: ':id',
          name: 'Customer',
          component: CustomerView,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/site/:site_id',
      component: LayoutDashboard,
      children: [
        {
          path: 'dashboard/:id',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/site/:site_id',
      component: LayoutBackend,
      children: [
        {
          path: 'campaigns',
          component: {
            render(c) { return c('router-view'); },
          },
          children: [
            {
              path: '',
              name: 'Campaigns List',
              component: CampaignsList,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'edit/:id?',
              name: 'Campaign Edit',
              component: CampaignEdit,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'carts',
          name: 'Carts',
          component: CartsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'coupons',
          name: 'Coupons',
          component: CouponsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'customers',
          name: 'Customers',
          component: CustomersList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'emailsequences',
          component: {
            render(c) { return c('router-view'); },
          },
          children: [
            {
              path: '',
              name: 'Email Sequence List',
              component: EmailSequenceList,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'edit/:id?',
              name: 'Email Sequence Edit',
              component: EmailSequenceEdit,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'orders',
          name: 'Orders',
          component: OrdersList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'products',
          name: 'Products',
          component: ProductsList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'sales-by-day',
          name: 'Sales By Day',
          component: SalesByDay,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'segments/customers',
          component: {
            render(c) { return c('router-view'); },
          },
          children: [
            {
              path: '',
              name: 'CustomerSegments',
              component: CustomerSegmentsList,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: ':id/edit',
              name: 'CustomerSegmentEdit',
              component: CustomerSegmentsEdit,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'new',
              name: 'Customer Segment Add',
              component: CustomerSegmentsEdit,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: 'subscriptions',
          name: 'Subscriptions',
          component: SubscriptionsList,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    {
      path: '/orders',

      component: LayoutBackend,
      children: [

        {
          path: '',
          component: {
            render(c) { return c('router-view'); },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ':id',
              name: 'Order',
              component: OrderView,
            },
          ],
        },
      ],
    },
    {
      path: '/carts',

      component: LayoutBackend,
      children: [

        {
          path: '',
          component: {
            render(c) { return c('router-view'); },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ':id',
              name: 'Cart',
              component: CartView,
            },
          ],
        },
      ],
    },
    {
      path: '/analytics',

      component: LayoutBackend,
      children: [

        {
          path: '',
          component: {
            render(c) { return c('router-view'); },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '',
              name: 'AnalyticsIndex',
              component: AnalyticsList,
            },
            {
              path: ':view',
              name: 'Analytics',
              component: AnalyticsList,
            },
          ],

        },
      ],
    },
    {
      path: '/products/:id/edit',
      component: LayoutBackend,
      children: [
        {
          path: '',
          name: 'Products Edit',
          component: ProductsEdit,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/help',
      redirect: { name: 'Help' },
      children: [
        {
          path: '/help',
          name: 'Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/'), '_blank';},
        },
        {
          path: '/help-add-site',
          name: 'Site Add Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/how-to-add-a-site-to-blue-odin/'), '_blank';},
        },
        {
          path: '/help-site-integrations',
          name: 'Sites Integrations Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/how-to-add-integrations-to-blue-odin/'), '_blank';},
        },
        {
          path: '/help-update-settings',
          name: 'User Setings Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/how-to-update-your-blue-odin-user-settings/'), '_blank';},
        },
        {
          path: '/help-dashboard',
          name: 'Dashboard Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/blue-odin-dashboard-tour/'), '_blank';},
        },
        {
          path: '/help-orders',
          name: 'Orders Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/woocommerce-orders-in-blue-odin/'), '_blank';},
        },
        {
          path: '/help-customers',
          name: 'Customers Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/woocommerce-customers-in-blue-odin/'), '_blank';},
        },
        {
          path: '/help-products',
          name: 'Products Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/woocommerce-products-in-blue-odin/'), '_blank';},
        },
        {
          path: '/help-coupons',
          name: 'Coupons Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/woocommerce-coupons-in-blue-odin/'), '_blank';},
        },
        {
          path: '/help-sales',
          name: 'Sales By Day Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/blue-odin-sales-by-day/'), '_blank';},
        },
        {
          path: '/help-domain',
          name: 'Org Settings Domains Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/how-to-validate-your-domains-spf-and-dkim-records/'), '_blank';},
        },
        {
          path: '/help-email-sequences',
          name: 'Email Sequence List Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/blue-odin-email-sequences/'), '_blank';},
        },
        {
          path: '/help-email-campaigns',
          name: 'Campaigns List Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/blue-odin-email-campaigns/'), '_blank';},
        },
        {
          path: '/help-customer-segments',
          name: 'CustomerSegments Help',
          beforeEnter() {window.open('https://www.blueodin.io/help/blue-odin-customer-segments/'), '_blank';},
        },
      ],
    },
    {
      path: '/forum',
      name: 'Forum',
      beforeEnter() {window.open('https://forum.blueodin.io'), '_blank';},
    },
    {
      // Handle unknown pages
      path: "*",
      component: Errors404,
    },
  ],
});



const checkForUnknownNamedRoute: NavigationGuard = (to, from, next) => {
  to.matched.length > 0
    ? next()
    : next(new Error("Can not find named route: " + to.name));
};

const checkForAuthenticationRequired: NavigationGuard = (to, from, next) => {
  //console.debug("beforeEach interceptor", to, from, next);
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    //console.debug("does not require authentication")
    next();
    return;
  }

  if (!store.getters.loggedIn) {
    //console.log("Not logged in, redirecting to login page");
    next({
      name: 'login',
    });
    return;
  }

  // Never prevent logging out
  if (to.name === 'logout') {
    next();
    return;
  }

  store.dispatch('fetchCurrentUser')

    .then(() => {
      // If the user has no sites, and is not visiting the onboarding page, make them.
      const isOnboardingEdit = to.name === 'Welcome Aboard Step 2' && to.query?.manual === "true";
      const isOnboardingAdd = to.name === 'Welcome Aboard Step 1';
      const hasSites = store.getters.currentUser.current_organisation.has_sites;
      if (!hasSites && !isOnboardingAdd && !isOnboardingEdit) {
        next({
          name: 'Welcome Aboard Step 1',
        });
        return;
      }
    });

  //console.log("continuing to page");

  next();
  return;
};

router.onError(err => {
  Sentry.captureException(err);
});

router.beforeEach(checkForUnknownNamedRoute);
router.beforeEach(checkForAuthenticationRequired);

export default router;
