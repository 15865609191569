import axios from "axios";
import PageOptions from "@/api/model/PageOptions";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import {WooSubscription, WooSubscriptionFactory, WooSubscriptionJson} from "@/api/model/WooSubscription";

export default class WooSubscriptionService {

  public async list(siteId: number | string, pageOptions?: PageOptions): Promise<PaginatedResponse<WooSubscription>> {
    return axios.get<PaginatedResponseJson<WooSubscriptionJson>>(`/api/sites/${siteId}/subscriptions?${pageOptions?.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooSubscriptionFactory));
  }

  /*
  public static async get(id: number): Promise<WooSubscription> {
    return axios.get<Resource<WooSubscriptionJson>>(`/api/products/${id}`)
      .then((resp) => WooSubscription.fromJSON(resp.data.data))
      .catch((err: AxiosError) => Promise.reject(LaravelError.fromAxios(err)));
  }

  public static async update(product: WooSubscription): Promise<WooSubscription> {
    return axios.put<Resource<WooSubscriptionJson>>(`/api/products/${product.id}`, product)
      .then((resp) => WooSubscription.fromJSON(resp.data.data))
      .catch((err: AxiosError) => Promise.reject(LaravelError.fromAxios(err)));
  }

  public static delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/products/${id}`)
      .catch((err: AxiosError) => Promise.reject(LaravelError.fromAxios(err)));
  }
  */

}
