import {WooAddress, WooAddressJson} from "@/api/model/WooAddress";
import {DateTime} from "luxon";
import {WooOrder, WooOrderJson} from "@/api/model/WooOrder";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";
import axios from "axios";


export interface WooCustomerJson {
    id: number;
    woo_id: number;
    first_name: string;
    last_name: string;
    username: string;
    created_at: string;
    updated_at: string;
    address_1: string;
  address_2: string;
    country: string;
    woo_site_id: number;
  email: string;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  role: string;
  is_paying_customer: boolean;
  avatar_url: string;
  billingAddress: WooAddressJson;
  shippingAddress: WooAddressJson;
  orders: WooOrderJson[];
  site: WooSiteJson | null;
}

export class WooCustomer {
    id = 0;
    woo_id = 0;
    first_name = '';
    last_name = '';
    company = '';
    username: string | null = null;
    created_at: Date = new Date();
    updated_at: Date = new Date();
    woo_site_id = 0;
  email = '';
  date_created: DateTime | null = null;
  date_created_gmt: DateTime | null = null;
  date_modified: DateTime | null = null;
  date_modified_gmt: DateTime | null = null;
  role = '';
  is_paying_customer = false;
  avatar_url = '';
  billingAddress: WooAddress | null = null;
  shippingAddress: WooAddress | null = null;
  orders: WooOrder[] = [];
  site: WooSite | null = null;

  get address_1(): string | undefined {
    return this.billingAddress?.address_1;
  }
  set address_1(address: string | undefined) {
    //
  }
  get address_2(): string | undefined {
    return this.billingAddress?.address_2;
  }
  set address_2(address: string | undefined) {
    //
  }
  get city(): string | undefined {
    return this.billingAddress?.address_2;
  }
  set city(city: string | undefined) {
    //
  }
  get postcode(): string | undefined {
    return this.billingAddress?.address_2;
  }
  set postcode(postcode: string | undefined) {
    //
  }
  get state(): string | undefined {
    return this.billingAddress?.address_2;
  }
  set state(state: string | undefined) {
    //
  }
  get country(): string | undefined {
    return this.billingAddress?.address_2;
  }
  set country(country: string | undefined) {
    //
  }
    static fromJSON(json: WooCustomerJson): WooCustomer {
        //console.debug("WooCustomer.fromJSON()", json);
        const response = Object.create(WooCustomer.prototype);
        return Object.assign(response, json, {
            createdAt: json.created_at ? new Date(json.created_at) : null,
            updatedAt: json.updated_at ? new Date(json.updated_at) : null,
          date_created: json.date_created ? DateTime.fromISO(json.date_created) : null,
          date_created_gmt: json.date_created_gmt ? DateTime.fromISO(json.date_created_gmt) : null,
          date_modified: json.date_modified ? DateTime.fromISO(json.date_modified) : null,
          date_modified_gmt: json.date_modified_gmt ? DateTime.fromISO(json.date_modified_gmt) : null,
          billingAddress: json.billingAddress ? WooAddress.fromJSON(json.billingAddress) : null,
          shippingAddress: json.shippingAddress ? WooAddress.fromJSON(json.shippingAddress) : null,
          orders: json.orders ? json.orders.map(json => WooOrder.fromJSON(json)) : [],
          site: json.site ? WooSite.fromJSON(json.site) : null,
        });
    }

    get isGuestCheckout(): boolean {
      return this.username === null;
    }

}

export class MapService{

  public async getGeocordinates (shippingAddress: string){
    const location = shippingAddress;
    const key = process.env.VUE_APP_MAPS_API_KEY;
    return axios.get(`https://maps.googleapis.com/maps/api/geocode/json/${location}/${key}`)
      .then((resp)=> console.log(resp))
      .catch((error) => console.log(error));

  }
}
