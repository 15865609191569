















































import Component from "vue-class-component";
import {Vue, Watch} from "vue-property-decorator";
import {Notification, Payload} from "@/api/NotificationService";
import {Getter} from "vuex-class";
import User from "@/api/model/User";

@Component
export default class NotificationDropdown extends Vue {
  @Getter
  private currentUser!: User;

  @Getter
  private notifications!: Notification[];

  created(): void {
    this.$store.dispatch('fetchNotifications');
  }

  @Watch('currentUser')
  private listenToNotifications() {
    if (!window.Echo) {
      console.warn("Echo not configured; notifications disabled");
      return;
    }
    window.Echo.private(`App.Models.User.${this.currentUser.id}`).listen(".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", (e: Payload) => {
      this.$store.commit("addNotification", Notification.fromPayload(e));
    });
    window.Echo.private(`App.Models.Organisation.${this.currentUser.current_organisation?.id}`).listen(".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", (e: Payload) => {
      this.$store.commit("addNotification", Notification.fromPayload(e));
    });
  }

  onRead(notification: Notification): void {
    console.log("onRead", notification);
  }

  onDelete(notification: Notification): void {
    //console.log("onDelete", notification);
    this.$store.dispatch('deleteNotification', notification);

  }
}
