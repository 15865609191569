import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import WooSiteService from "@/api/WooSiteService";
import {RootState} from "@/store/RootState";
import {WooSite} from "@/api/model/WooSite";
import Optional from "optional-js";

const CURRENT_SITE_KEY = "current_site";

interface SitesState {
  sites: Array<WooSite>;
  currentSiteId: number;
  currentSite: WooSite;
}

const wooSiteService = new WooSiteService();

function getDefaultCurrentSiteId(): number  {
  const item = localStorage.getItem(CURRENT_SITE_KEY);
  if (item === "all") {
    return 0;
  }
  return parseInt(item ?? "0");
}

function getDefaultState(): SitesState {
  return {
    sites: [],
    currentSiteId: getDefaultCurrentSiteId(),
    currentSite: new WooSite(),
  };
}

const state: SitesState = getDefaultState();

const getters: GetterTree<SitesState, RootState> = {
  currentSiteId(state): number {
    return state.currentSiteId;
  },
  currentSite(state): WooSite {
    return state.currentSite;
  },
  getSite: (state) => (id: number): Optional<WooSite> => Optional.ofNullable(state.sites.find(site => site.id == id)),
  allSites(state): Array<WooSite> {
    return state.sites;
  },
};

const actions: ActionTree<SitesState, RootState> = {
  fetchAllSites(context: ActionContext<SitesState, RootState>) {
    //console.log("fetchAllSites", context);
    wooSiteService.list()
      .then((sites: WooSite[]) => {
        context.commit('setSites', sites);

        const site = sites.find((site: WooSite) => site.id == context.state.currentSiteId);
        if (site) {
          context.commit('setCurrentSite', site);
          return;
        }

        context.commit('setCurrentSite',context.state.sites[0]);

      });
  },
  resetSitesState (context: ActionContext<SitesState, RootState>) {
    context.commit('resetState');
  },
};

const mutations: MutationTree<SitesState> = {
  setSites(state, sites: Array<WooSite>) {
    //console.log("commit setSites", sites);
    state.sites = sites;
  },
  setCurrentSite(state, site: WooSite) {
    //console.log("commit setCurrentSite", site);
    state.currentSite = site;
    state.currentSiteId = site.id != "all" ?site.id ?? 0 : 0;
    localStorage.setItem(CURRENT_SITE_KEY, String(state.currentSiteId));
  },
  setCurrentSiteId(state, siteId: number) {
    //console.log("commit setCurrentSiteId", siteId);
    state.currentSiteId = siteId;
    const site = state.sites.find((site: WooSite) => site.id == state.currentSiteId);
    state.currentSite =  site ?? WooSite.allSite('USD');

    localStorage.setItem(CURRENT_SITE_KEY, String(state.currentSiteId));
  },

  resetState (state) {
    Object.assign(state, getDefaultState());
  },
};

const namespaced = false;

export const sites: Module<SitesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
