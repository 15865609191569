





















import Vue from "vue";

export default Vue.extend({
  name: 'BaseFooter',
  props: {
    classes: String,
  },
});
