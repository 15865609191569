import {WooSite} from "@/api/model/WooSite";
import axios, {AxiosResponse} from "axios";
import Campaign, {CampaignFactory, CampaignJson} from "@/api/model/Campaign";
import {Resource} from "@/api/model/Resource";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import PageOptions from "@/api/model/PageOptions";

interface StatusResponse {
  status: "live" | "draft";
}


export default class CampaignService {
  public async list(siteId: number | string, pageOptions: PageOptions): Promise<PaginatedResponse<Campaign>> {
    return axios.get<PaginatedResponseJson<CampaignJson>>(`/api/sites/${siteId}/campaigns?${pageOptions.query()}`)
      .then((resp) =>PaginatedResponse.fromJSON(resp.data, new CampaignFactory));
  }

  public async create(site: WooSite, campaign: Campaign): Promise<Campaign> {
    if (site.id === "all" || site.id === null) {
      throw "Invalid site id";
    }
    return this.createBySiteId(site.id, campaign);
  }

  public async createBySiteId(siteId: number, campaign: Campaign): Promise<Campaign> {
    return axios.post<Resource<CampaignJson>>(`/api/sites/${siteId}/campaigns`, campaign)
      .then((resp) => Campaign.fromJSON(resp.data.data));
  }

  public async get(id: number): Promise<Campaign> {
    return axios.get<Resource<CampaignJson>>(`/api/campaigns/${id}`)
      .then((resp) => Campaign.fromJSON(resp.data.data));
  }

  public async update(campaign: Campaign): Promise<Campaign> {
    return axios.put<Resource<CampaignJson>>(`/api/campaigns/${campaign.id}`, campaign)
      .then((resp) => Campaign.fromJSON(resp.data.data));
  }

  public async delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/campaigns/${id}`);
  }

  public async updateStatusById(id: number, live: boolean): Promise<AxiosResponse<StatusResponse>> {
    return axios.put<StatusResponse>(`/api/campaigns/${id}/status`, { status: live ? 'live': 'draft'});
  }
}
