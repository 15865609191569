





























import dashboardBus from "@/views/dashboard/dashboardBus";
import {mapMutations} from "vuex";

export default {
  name: 'AddWidgetList',
  data () {
    return {
      widgets: [
        {

          name: "KPIs",
          children: [
            {
              icon: 'si si-speedometer',
              color: 'dashboard-blue',
              name: 'Average Order Value',
              subtitle: 'Average value of each order',
              widget: 'AvgOrderValueWidget',
            },
            {
              icon: 'si si-speedometer',
              color: 'dashboard-blue',
              name: 'Average Profit Per Order',
              subtitle: 'Average profit generated on each order',
              widget: 'AvgProfitPerOrderWidget',
            },
            {
              icon: 'si si-speedometer',
              color: 'dashboard-blue',
              name: 'Average Unit Count Per Order',
              subtitle: 'Average units sold on each order',
              widget: 'AvgUnitsCountPerOrderWidget',
            },
            {
              icon: 'si si-wallet',
              color: 'dashboard-blue',
              name: 'Cost of Goods',
              subtitle: 'Cost of goods sold',
              widget: 'CostOfGoodsWidget',
            },
            {
              icon: 'si si-tag',
              color: 'dashboard-blue',
              name: 'Coupons',
              subtitle: 'Discounts generated by coupons',
              widget: 'CouponsWidget',
            },
            {
              icon: 'si si-list',
              color: 'dashboard-blue',
              name: 'Order Count',
              subtitle: 'Number of orders completed',
              widget: 'OrdersCountWidget',
            },
            {
              icon: 'si si-rocket',
              color: 'dashboard-blue',
              name: 'Profit Margin',
              subtitle: 'Percentage of profits across all orders',
              widget: 'ProfitMarginWidget',
            },
            {
              icon: 'si si-rocket',
              color: 'dashboard-blue',
              name: 'Profit',
              subtitle: 'Amount of income that remains after accounting for all expenses',
              widget: 'ProfitWidget',
            },
            {
              icon: 'si si-action-undo',
              color: 'dashboard-blue',
              name: 'Refunds',
              subtitle: 'Refund value and percentage of revenue',
              widget: 'RefundsWidget',
            },
            {
              icon: 'si si-rocket',
              color: 'dashboard-blue',
              name: 'Revenue',
              subtitle: 'Income generated by the sale of goods',
              widget: 'RevenueWidget',
            },
            {
              icon: 'si si-basket-loaded',
              color: 'dashboard-blue',
              name: 'Units Sold',
              subtitle: 'Individual units sold across all orders',
              widget: 'UnitsCountWidget',
            },
            {
              icon: 'si si-envelope',
              color: 'dashboard-blue',
              name: 'Email Sequence Sent Count',
              subtitle: 'Total number of emails sent for all email sequences',
              widget: 'EmailSequenceSentCountWidget',
            },
            {
              icon: 'si si-envelope-open',
              color: 'dashboard-blue',
              name: 'Email Sequence Rate Stats',
              subtitle: 'Percentage of emails opened, clicked, bounced or unsubscribed',
              widget: 'EmailSequenceOpenRateWidget',
            },
            {
              icon: 'si si-envelope-open',
              color: 'dashboard-blue',
              name: 'Blue Odin Email Revenue KPI',
              subtitle: 'Blue Odin Email Revenue',
              widget: 'BlueOdinEmailRevenueKPI',
            },
          ],
        },
        {
          name: "Charts",
          children: [
            {
              icon: 'si si-graph',
              color: 'dashboard-green',
              name: 'Sales Revenue',
              subtitle: 'Revenue generated by sales by day',
              widget: 'OrdersChart',
              defaultMeta: {size: 2},
            },
            {
              icon: 'si si-graph',
              color: 'dashboard-green',
              name: 'UTM Source',
              subtitle: 'Revenue generated by customer source',
              widget: 'UTMSourceWidget',
              defaultMeta: {size: 1},
            },
            {
              icon: 'si si-graph',
              color: 'dashboard-green',
              name: 'Top Converting Sequences',
              subtitle: 'Top Converting Email Sequences',
              widget: 'TopConvertingSequenceWidget',
              defaultMeta: {size: 1},
            },
          ],
        },
        {
          name: "Tables",
          children: [
            {
              icon: 'si si-basket',
              color: 'dashboard-orange',
              name: 'Products Summary',
              subtitle: 'Sales summary by product',
              widget: 'ProductsTableWidget',
              defaultMeta: {size: 3},
            },

              {
              icon: 'si si-envelope',
              color: 'dashboard-orange',
              name: 'Emails Sequence',
              subtitle: 'Email sequence statistics',
              widget: 'EmailSequenceWidget',
              defaultMeta: {size: 2},
            },
              {
              icon: 'si si-envelope',
              color: 'dashboard-orange',
              name: 'All Sites Table',
              subtitle: 'Statistics for all the sites added',
              widget: 'AllSitesTableWidget',
              defaultMeta: {size: 2},
            },
          ],
        },
        {
          name: "Timelines",
          children: [
            {
              icon: 'si si-clock',
              color: 'dashboard-red',
              name: 'Order Timeline',
              subtitle: 'Timeline showing the most recent order events',
              widget: 'OrdersTimelineWidget',
            },
          ],
        },
        {
          name: "Utility",
          children: [
            {
              icon: 'si si-pencil',
              color: 'dashboard-purple',
              name: 'Heading',
              subtitle: 'Generates a header to organize the items displayed on the dashboard.',
              widget: 'HeaderWidget',
              defaultMeta: {size: 2, text: 'Header'},
            },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      sideOverlay: 'sideOverlay',
    }),
    addWidget(name: string, meta: Record<string, unknown>): void {
      dashboardBus.$emit('add-widget', {name: name, meta: meta});
      this.sideOverlay({mode: 'toggle'});
    },
  },
};
