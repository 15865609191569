import {DateTime} from "luxon";
import {FromJsonFactory} from "./PaginatedResponse";
import Domain, {DomainJson} from "@/api/model/Domain";

export interface WooSiteJson {
  id: number;
  name: string | null;
  url: string;
  consumer_key: string;
  consumer_secret: string;
  country_code: string | null;
  currency: string;
  is_importing_orders: boolean;
  created_at: string | null;
  updated_at: string | null;
  organisation_id: number;
  failure_message: string | null;
  sender_name: string;
  sender_address: string;
  sender_domain: DomainJson;
  sender_domain_id: number;
  logo_url: string;
  import_paused: boolean;
  has_subscriptions: boolean;
  plugin_version: string;
}

export class WooSite {
  id: number | null | "all" = null;
  name = 'Unknown';
  url: string | null = null;
  consumer_key: string | null = null;
  consumer_secret: string | null = null;
  country_code: string | null = null;
  currency = 'USD';
  is_importing_orders = false;
  organisation_id: number | null = null;
  failure_message: string | null = null;
  sender_name = '';
  sender_address = '';
  sender_domain: Domain | null = new Domain();
  sender_domain_id = 0;
  logo_url: string | null = null;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  import_paused = false;
  has_subscriptions = false;
  stats: Record<string, number> = {};
  plugin_version: string | null = null;

  private moneyFormatter!: Intl.NumberFormat;

  static fromJSON(json: WooSiteJson): WooSite {
    //console.debug("WooSite.fromJSON()", json);
    const response = Object.create(WooSite.prototype);
    return Object.assign(response, json, {
      name: json.name ? json.name : json.url,
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.local(),
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : DateTime.local(),
      sender_domain: json.sender_domain ? Domain.fromJSON(json.sender_domain) : null,
    });
  }

  getMoneyFormatter(): Intl.NumberFormat {
    if (!this.moneyFormatter) {
      this.moneyFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: this.currency,
      });
    }
    return this.moneyFormatter;
  }

  getCurrencySymbol(): string {
    const moneyFormatter = this.getMoneyFormatter();
    const parts = moneyFormatter.formatToParts();
    return parts.find(part => part.type == 'currency')?.value ?? this.currency;

  }

  static allSite(currency: string): WooSite {
    const wooSite = new WooSite();
    wooSite.id = "all";
    wooSite.name = "All Sites";
    wooSite.currency = currency;
    return wooSite;
  }

  static compareByName(a: WooSite, b: WooSite): -1 | 0 | 1 {
    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
  }
}

export class WooSiteFactory implements FromJsonFactory<WooSite, WooSiteJson> {
  fromJSON(json: WooSiteJson): WooSite {
    return WooSite.fromJSON(json);
  }
}
