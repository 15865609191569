




























































// Import main layout components
import BaseHeader from '@/layouts/partials/Header.vue';
import BaseSidebar from '@/layouts/partials/Sidebar.vue';
import BaseSideOverlay from '@/layouts/partials/SideOverlay.vue';
import BaseFooter from '@/layouts/partials/Footer.vue';
import NewSiteAlert from '@/components/NewSiteAlert.vue';
import Vue from "vue";
import NoStripeSubscriptionWarning from "@/components/NoStripeSubscriptionWarning.vue";

export default Vue.extend({
  name: 'BaseLayout',
  components: {
    NoStripeSubscriptionWarning,
    BaseHeader,
    BaseSidebar,
    BaseSideOverlay,
    BaseFooter,
    NewSiteAlert,
  },
  props: {
    layoutClasses: Object,
  },
  computed: {
    classContainer () {
      return {
        'sidebar-r': this.$store.state.layout.sidebar && !this.$store.state.settings.sidebarLeft,
        'sidebar-mini': this.$store.state.layout.sidebar && this.$store.state.settings.sidebarMini,
        'sidebar-o':  this.$store.state.layout.sidebar && this.$store.state.settings.sidebarVisibleDesktop,
        'sidebar-o-xs': this.$store.state.layout.sidebar && this.$store.state.settings.sidebarVisibleMobile,
        'sidebar-dark': this.$store.state.layout.sidebar && this.$store.state.settings.sidebarDark,
        'side-overlay-o': this.$store.state.layout.sideOverlay && this.$store.state.settings.sideOverlayVisible,
        'side-overlay-hover': this.$store.state.layout.sideOverlay && this.$store.state.settings.sideOverlayHoverable,
        'enable-page-overlay': this.$store.state.layout.sideOverlay && this.$store.state.settings.pageOverlay,
        'page-header-fixed': this.$store.state.layout.header && this.$store.state.settings.headerFixed,
        'page-header-dark': this.$store.state.layout.header && this.$store.state.settings.headerDark,
        'main-content-boxed': this.$store.state.settings.mainContent === 'boxed',
        'main-content-narrow': this.$store.state.settings.mainContent === 'narrow',
        'rtl-support': this.$store.state.settings.rtlSupport,
        'side-trans-enabled': this.$store.state.settings.sideTransitions,
        'side-scroll': true,
      };
    },
  },
  created () {
    // Set default color theme
    this.$store.commit('setColorTheme', { theme: this.$store.getters.appColorTheme });
  },
});
