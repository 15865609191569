






























import BaseLayout from '../Base.vue';
import Vue from "vue";
import AddWidgetList from "@/views/dashboard/AddWidgetList.vue";

const LayoutDashboard = Vue.extend({
  name: 'LayoutDashboard',
  components: {
    AddWidgetList,
    BaseLayout,
  },
  data () {
    return {
      // Override and set custom CSS classes to the container of each base layout element
      layoutClasses: {
        sidebar: '',
        header: '',
        footer: '',
      },
    };
  },
  created () {
    // Set default elements for this layout
    this.$store.commit('setLayout', {
      header: true,
      sidebar: true,
      sideOverlay: true,
      footer: true,
    });

    // Set various template options
    this.$store.commit('headerStyle', { mode: 'light'});
    this.$store.commit('mainContent', { mode: 'narrow'});
  },
});

export default LayoutDashboard;
