import {WooSite} from "@/api/model/WooSite";
import axios, {AxiosResponse} from "axios";
import {Resource} from "@/api/model/Resource";
import CustomerSegment, {CustomerSegmentFactory, CustomerSegmentJson} from "@/api/model/CustomerSegment";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import PageOptions from "@/api/model/PageOptions";
import {ResourceCollection} from "@/api/model/ResourceCollection";



export default class CustomerSegmentService {
  public async list(siteId: number | string, pageOptions: PageOptions): Promise<PaginatedResponse<CustomerSegment>> {
    return axios.get<PaginatedResponseJson<CustomerSegmentJson>>(`/api/sites/${siteId}/customer-segments?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new CustomerSegmentFactory()));
  }

  public async getAllBySiteId(siteId: number): Promise<CustomerSegment[]> {
    return axios.get<ResourceCollection<CustomerSegmentJson>>(`/api/sites/${siteId}/customer-segments`)
      .then((resp) => resp.data.data.map(json => CustomerSegment.fromJSON(json)));
  }

  public async create(site: WooSite, segment: CustomerSegment): Promise<CustomerSegment> {
    return axios.post<Resource<CustomerSegmentJson>>(`/api/sites/${site.id}/customer-segments`, segment)
      .then((resp) => CustomerSegment.fromJSON(resp.data.data));
  }

  public async get(id: number): Promise<CustomerSegment> {
    return axios.get<Resource<CustomerSegmentJson>>(`/api/customer-segments/${id}`)
      .then((resp) => CustomerSegment.fromJSON(resp.data.data));
  }

  public async update(segment: CustomerSegment): Promise<CustomerSegment> {
    return axios.put<Resource<CustomerSegmentJson>>(`/api/customer-segments/${segment.id}`, segment)
      .then((resp) => CustomerSegment.fromJSON(resp.data.data));
  }

  public async delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/customer-segments/${id}`);
  }

  public async createOrUpdate(site: WooSite, segment: CustomerSegment): Promise<CustomerSegment> {
    if (segment.id) {
      return this.update(segment);
    }
    return this.create(site, segment);
  }
}
