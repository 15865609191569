import {CartItem, CartItemJson} from "@/api/model/CartItem";
import {FromJsonFactory} from "@/api/model/PaginatedResponse";
import {DateTime} from "luxon";
import {WooSite, WooSiteJson} from "@/api/model/WooSite";
import "@/lib";
import {WooOrder, WooOrderJson} from "@/api/model/WooOrder";
import {WooCustomer, WooCustomerJson} from "@/api/model/WooCustomer";

export interface CartJson {
  id: number;
  created_at: string | null;
  updated_at: string | null;
  external_id: number;
  email_address: string | null;
  customer_details: string | null;
  order_total: number;
  abandonment_date: string | null;
  captured_by: string | null;
  cart_status: string | null;
  site_id: number;
  order: WooOrderJson | null;
  items: CartItemJson[];
  site: WooSiteJson;
  customer: WooCustomerJson | null;
}



export class Cart {
  id = 0;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  external_id = 0;
  email_address = 'unknown';
  customer_details = 'unknown';
  order_total = 0;
  abandonment_date = DateTime.local();
  captured_by = 'unknown';
  cart_status = 'unknown';
  site_id = 0;
  items = [] as CartItem[];
  order: WooOrder | null = null;
  site = WooSite.allSite('USD');
  customer: WooCustomer | null = null;

  static fromJSON(json: CartJson): Cart {
    //console.debug("Cart.fromJSON()", json);
    const response = Object.create(Cart.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : null,
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : null,
      items: json.items ? json.items.map(item => CartItem.fromJSON(item)) : [],
      order: json.order ? WooOrder.fromJSON(json.order) : null,
      site: json.site ? WooSite.fromJSON(json.site) : WooSite.allSite('USD'),
      abandonment_date: json.abandonment_date ? DateTime.fromISO(json.abandonment_date) : null,
      customer: json.customer ? WooCustomer.fromJSON(json.customer) : null,
    });
  }
}

export class CartFactory implements  FromJsonFactory<Cart, CartJson> {
  fromJSON(json: CartJson): Cart {
    return Cart.fromJSON(json);
  }

}
