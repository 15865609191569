import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {default as notificationService, Notification} from "@/api/NotificationService";
import {RootState} from "@/store/RootState";

interface NotificationsState {
  notifications: Notification[];
  notificationIds: Set<string>;
}

function getDefaultState(): NotificationsState {
  //console.debug("Notifications getDefaultState()", localStorage);
  return {
    notifications: [],
    notificationIds: new Set(),
  };
}

const state: NotificationsState = getDefaultState();

const getters: GetterTree<NotificationsState, RootState> = {
  notifications(state): Notification[] {
    //console.debug("get currentUser", state);
    return state.notifications ?? [];
  },
};

const actions: ActionTree<NotificationsState, RootState> = {
  fetchNotifications(context: ActionContext<NotificationsState, RootState>): Promise<Notification[]> {
    //console.debug("dispatch fetchNotifications");
      return notificationService.list()
        .then(notifications => {
          context.commit('setNotifications', notifications);
          return notifications;
        });
  },
  deleteNotification(context: ActionContext<NotificationsState, RootState>, notification: Notification) {
    notificationService.delete(notification)
      .then(() => context.commit('deleteNotification', notification));
  },
};

const mutations: MutationTree<NotificationsState> = {
  setNotifications(state, notifications: Notification[]) {
    //console.debug("commit notifications", state, notifications);
    state.notifications = notifications;
    state.notificationIds.clear();
    state.notifications.forEach(notification => state.notificationIds.add(notification.id));
    //console.debug("current state:", state, localStorage)
  },
  deleteNotification(state, notification: Notification) {
    const index = state.notifications.findIndex(n => n.id === notification.id);
    state.notifications.splice(index, 1);
    state.notificationIds.delete(notification.id);
  },
  addNotification(state, notification: Notification) {
    if (!state.notificationIds.has(notification.id)) {
      state.notifications.push(notification);
      state.notificationIds.add(notification.id);
    }
  },
};

const namespaced = false;

export const notifications: Module<NotificationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
