import axios, {AxiosResponse} from "axios";
import Organisation, {OrganisationJson} from "@/api/model/Organisation";

interface OrderCount {
  count: number;
}

export default class OrganisationService {
  public static async update(org: Organisation, currency: string): Promise<Organisation> {
    return axios.put<OrganisationJson>(`/api/organisations/${org.id}`, {global_currency: currency})
      .then((resp) => Organisation.fromJSON(resp.data));
  }

  public async delete(orgId: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/organisations/${orgId}`);
  }

  public async getOrderCountByOrganisation(orgId: number): Promise<OrderCount> {
    return axios.get<OrderCount>(`/api/organisations/${orgId}/orders/count`)
      .then((resp) => resp.data);
  }
}
