import {DateTime} from "luxon";

export interface PricePlanJson {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  levels: PlanLevelJson[];
}

export default class PricePlan {
  id = 0;
  created_at = DateTime.local();
  updated_at = DateTime.local();
  levels: PlanLevel[] = [];

  static fromJSON(json: PricePlanJson): PricePlan {
    //console.debug("Dashboard.fromJSON", json);
    const response = Object.create(PricePlan.prototype);
    return Object.assign(response, json, {
      created_at: json.created_at ? DateTime.fromISO(json.created_at) : DateTime.local(),
      updated_at: json.updated_at ? DateTime.fromISO(json.updated_at) : DateTime.local(),
      levels: json.levels ? json.levels.map(json => PlanLevel.fromJSON(json)) : [],
    });
  }
}

export interface PlanLevelJson {
  name: string,
  maxOrders: number,
  price: number,
  description?: string,
}

export class PlanLevel {
  name = '';
  max_orders = 9999999;
  price = 0.00;
  description? = '';
  per_extra_order = 0.00;

  static fromJSON(json: PlanLevelJson): PlanLevel {
  //console.debug("Dashboard.fromJSON", json);
  const response = Object.create(PricePlan.prototype);
  return Object.assign(response, json, {
  });
}
}
