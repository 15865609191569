








































import Vue, {VueConstructor} from "vue";
import User from "@/api/model/User";
import {mapGetters} from "vuex";
import NotificationDropdown from "@/layouts/partials/NotificationDropdown.vue";
import UserDropdown from "../../components/UserDropdown.vue";
import HelpHeaderButton from "../../components/HelpHeaderButton.vue";


interface VuexBindings {
  currentUser: User | undefined;
}

const BaseHeader = (Vue as VueConstructor<Vue & VuexBindings>).extend({
    components: {
      UserDropdown,
      NotificationDropdown,
      HelpHeaderButton,
    },
    computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
});

export default BaseHeader;
