import axios, {AxiosResponse} from "axios";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import {Resource} from "@/api/model/Resource";
import {WooSite, WooSiteJson, WooSiteFactory} from "@/api/model/WooSite";
import PageOptions from "@/api/model/PageOptions";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import {WooOrder, WooOrderFactory, WooOrderJson} from "@/api/model/WooOrder";
import {DateRange} from "@/api/model/DateRange";

interface WooSiteIntegrationJson {
  id: number;
  name: string|null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: object;
}

export class WooSiteIntegration {
  id: number|null = null;
  name: string|null = null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: object = {};

  static fromJSON(json: WooSiteIntegrationJson): WooSiteIntegration {
    //console.debug("WooSiteIntegration.fromJSON()", json);
    const response = Object.create(WooSiteIntegration.prototype);
    return Object.assign(response, json, {
    });
  }
}

interface WooSiteRedirectJson {
  data: WooSiteJson;
  meta: { redirect: string };
}

export class WooSiteRedirect {
  site!: WooSite;
  redirect = '';
  static fromJSON(json: WooSiteRedirectJson): WooSiteRedirect {
    //console.debug("WooSiteRedirect.fromJSON()", json);
    const response = Object.create(WooSiteRedirect.prototype);
    return Object.assign(response, json, {
      site: WooSite.fromJSON(json.data),
      redirect: json.meta.redirect,
    });
  }
}

export default class WooSiteService {

  public async list(): Promise<WooSite[]> {
    return axios.get<ResourceCollection<WooSiteJson>>('/api/sites')
      .then((resp) => resp.data.data.map(site => WooSite.fromJSON(site)));
  }

  public async listWithPagination(pageOptions: PageOptions): Promise<PaginatedResponse<WooSite>>{
    return axios.get<PaginatedResponseJson<WooSiteJson>>(`/api/sites?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooSiteFactory));
  }

  public async get(id: number): Promise<WooSite> {
    return axios.get<Resource<WooSiteJson>>(`/api/sites/${id}`)
      .then((resp) => WooSite.fromJSON(resp.data.data));
  }

  public async getById(id: number): Promise<WooSite> {
    return axios.get<Resource<WooSiteJson>>(`/api/sites/${id}`)
      .then((resp) => WooSite.fromJSON(resp.data.data));
  }

  public static async getIntegrations(site: WooSite): Promise<WooSiteIntegration[]> {
    return axios.get<ResourceCollection<WooSiteIntegrationJson>>(`/api/sites/${site.id}/integrations`)
      .then((resp) => resp.data.data.map(json => WooSiteIntegration.fromJSON(json)));
  }

  public async create(url: string, onboarding = false): Promise<WooSiteRedirect> {
    return axios.post<WooSiteRedirectJson>(`/api/sites/`, { url: url, return_to: onboarding })
      .then((resp) => WooSiteRedirect.fromJSON(resp.data));
  }

  public async update(site: WooSite): Promise<WooSite> {
    return axios.put<Resource<WooSiteJson>>(`/api/sites/${site.id}`, site)
      .then((resp) => WooSite.fromJSON(resp.data.data));
  }

  public delete(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/sites/${id}`);
  }

  public reenableImports(id: number): Promise<AxiosResponse<void>> {
    return axios.put<void>(`/api/sites/${id}/status`, {import: true});
  }

  public async events(siteId: number | "all", pageOptions: PageOptions): Promise<PaginatedResponse<WooOrder>> {
    return axios.get<PaginatedResponseJson<WooOrderJson>>(`/api/sites/${siteId}/events?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooOrderFactory));
  }

  public async listWithStats(siteId: number | "all", range: DateRange): Promise<WooSite[]> {
    return axios.get<ResourceCollection<WooSiteJson>>(`/api/sites/${siteId}/stats?${range.getParams()}`)
      .then((resp) => resp.data.data.map(site => WooSite.fromJSON(site)));
  }
}
