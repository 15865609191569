




import BaseLayout from '../Base.vue';
import Vue from "vue";

export default Vue.extend({
  name: 'LayoutSimple',
  components: {
    BaseLayout,
  },
  created () {
    // Set default elements for this layout
    this.$store.commit('setLayout', {
      header: false,
      sidebar: false,
      sideOverlay: false,
      footer: false,
    });

    // Set various template options
    this.$store.commit('mainContent', { mode: 'full'});
  },
});
