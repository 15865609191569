/*
 * Main and demo navigation arrays
 */

export default {
  'main': [
    {
      name: 'Metrics',
      heading: true,
    },
    {
      name: 'Orders',
      icon: 'si si-present',
      to: {name: 'Orders', params: {site_id: 0 }},
    },
    /*{
      name: 'Carts',
      icon: 'si si-basket',
      to: {name: 'Carts', params: {site_id: 0 }},
    },*/
    {
      name: 'Customers',
      icon: 'si si-user',
      to: {name: 'Customers', params: {site_id: 0 }},
    },
    {
      name: 'Products',
      icon: 'si si-list',
      to: {name: 'Products', params: {site_id: 0 }},
    },
    {
      name: 'Coupons',
      icon: 'si si-tag',
      to: {name: 'Coupons', params: {site_id: 0 }},
    },
    {
      name: 'Sales By Day',
      icon: 'si si-rocket',
      to: {name: 'Sales By Day', params: {site_id: 0 }},
    },
    {
      name: 'Analytics',
      icon: 'si si-chart',
      to: '/analytics',
    },
    {
      name: 'Subscriptions',
      icon: 'si si-calendar',
      to: {name: 'Subscriptions', params: {site_id: 0 }},
    },
    {
      name: 'Email',
      heading: true,
    },
    {
      name: 'Email Sequences',
      icon: 'si si-envelope',
      to: {name: 'Email Sequence List', params: {site_id: 0 }},
    },
    {
      name: 'Campaigns List',
      icon: 'si si-flag',
      to: {name: 'Campaigns List', params: {site_id: 0 }},
    },
    {
      name: 'Segments',
      icon: 'si si-grid',
      sub: [
        {
          name: 'Customers Segments',
          icon: 'si si-users',
          to: {name: 'CustomerSegments', params: {site_id: 0 }},
        },
      ],
    },
    {
      name: 'Help',
      heading: true,
    },
    {
      name: 'Forum',
      icon: 'si si-bubbles',
      to: {name: 'Forum'},

    },
    {
      name: 'Documentation',
      icon: 'si si-notebook',
      to: {name: 'Help'},
    },
  ],
};
