import axios, {AxiosResponse} from "axios";
import {Resource} from "@/api/model/Resource";
import PageOptions from "@/api/model/PageOptions";
import {PaginatedResponse, PaginatedResponseJson} from "@/api/model/PaginatedResponse";
import {WooSite} from "@/api/model/WooSite";
import Optional from "optional-js";
import {EmailSequence, EmailSequenceFactory, EmailSequenceJson} from "@/api/model/EmailSequence";
import {WooOrder, WooOrderFactory, WooOrderJson} from "@/api/model/WooOrder";
import {ResourceCollection} from "@/api/model/ResourceCollection";
import {DateRange} from "@/api/model/DateRange";
import {Cart, CartFactory, CartJson} from "@/api/model/Cart";

export interface DelayPeriod {
  value: number;
  text: string;
  multiplier: number;
}


export class EmailTrigger {
  readonly id: number;
  readonly category: string;
  readonly event: string;

  constructor(id: number, category: string, event: string) {
    this.id = id;
    this.category = category;
    this.event = event;
  }

  static byId(id: number): Optional<EmailTrigger> {
    return Optional.ofNullable(triggers.find(trigger => trigger.id === id));
  }
}

// These values must remain in sync with the email_triggers database table
export const triggers = [
  new EmailTrigger(1, 'None', 'None'),
  new EmailTrigger(2, 'Order', 'Processing'),
  new EmailTrigger(3, 'Order', 'Completed'),
  new EmailTrigger(4, 'Order', 'Refunded'),
  new EmailTrigger(5, 'Order', 'Cancelled'),
  new EmailTrigger(6, 'Cart', 'Abandoned'),
];

interface StatusResponse {
  status: "live" | "draft";
}

export class PreviewResponse {
  id = 0;
  name = '';
  to = '';
  from = '';
  subject = '';
  body = '';
  static fromJSON(json: PreviewResponseJson): PreviewResponse {
    //console.debug("EmailSequence.fromJSON", json);
    const response = Object.create(PreviewResponse.prototype);
    return Object.assign(response, json, {
    });
  }
}

interface PreviewResponseJson {
  id: number;
  name: string;
  to: string;
  from: string;
  subject: string;
  body: string;
}

export default class EmailSequenceService {
  public async list(
    siteId: number | string,
    pageOptions: PageOptions,
    dateRange: DateRange | null = null
  ): Promise<PaginatedResponse<EmailSequence>> {
    const dateParams: string = dateRange?.getParams() ?? '';
    return axios.get<PaginatedResponseJson<EmailSequenceJson>>(`/api/sites/${siteId}/emailsequences?${pageOptions.query()}&${dateParams}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new EmailSequenceFactory));
  }

  public async get(id: number): Promise<EmailSequence> {
    return axios.get<Resource<EmailSequenceJson>>(`/api/emailsequences/${id}`)
      .then((resp) => EmailSequence.fromJSON(resp.data.data));
  }

  public async getOrders(id: number, pageOptions: PageOptions): Promise<PaginatedResponse<WooOrder>> {
    return axios.get<PaginatedResponseJson<WooOrderJson>>(`/api/emailsequences/${id}/orders?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new WooOrderFactory));
  }

  public async getCarts(id: number, pageOptions: PageOptions): Promise<PaginatedResponse<Cart>> {
    return axios.get<PaginatedResponseJson<CartJson>>(`/api/emailsequences/${id}/carts?${pageOptions.query()}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new CartFactory));
  }

  public async getOrderPreviews(sequence: EmailSequence, order: WooOrder): Promise<PreviewResponse[]> {
    return axios.post<ResourceCollection<PreviewResponseJson>>(`/api/emailsequences/${sequence.id}/orders/${order.id}/previews`, sequence)
      .then((resp) => resp.data.data.map(json => PreviewResponse.fromJSON(json)));
  }

  public async getCartPreviews(sequence: EmailSequence, cart: Cart): Promise<PreviewResponse[]> {
    return axios.post<ResourceCollection<PreviewResponseJson>>(`/api/emailsequences/${sequence.id}/carts/${cart.id}/previews`, sequence)
      .then((resp) => resp.data.data.map(json => PreviewResponse.fromJSON(json)));
  }

  public async createOrUpdate(site: WooSite, emailSequence: EmailSequence): Promise<EmailSequence> {
    if (emailSequence.id) {
      return this.update(emailSequence);
    }
    return this.create(site, emailSequence);
  }

  public async createOrUpdateBySiteId(siteId: number, emailSequence: EmailSequence): Promise<EmailSequence> {
    if (emailSequence.id) {
      return this.update(emailSequence);
    }
    return this.createBySiteId(siteId, emailSequence);
  }

  public async create(site: WooSite, emailSequence: EmailSequence): Promise<EmailSequence> {
    if (site.id === "all" || site.id === null) {
      throw "Invalid site id";
    }
    return this.createBySiteId(site.id, emailSequence);
  }

  public async createBySiteId(siteId: number, emailSequence: EmailSequence): Promise<EmailSequence> {
    return axios.post<Resource<EmailSequenceJson>>(`/api/sites/${siteId}/emailsequences/`, emailSequence)
      .then((resp) => EmailSequence.fromJSON(resp.data.data));
  }

  public async update(emailSequence: EmailSequence): Promise<EmailSequence> {
    return axios.put<Resource<EmailSequenceJson>>(`/api/emailsequences/${emailSequence.id}`, emailSequence)
      .then((resp) => EmailSequence.fromJSON(resp.data.data));
  }

  public async delete(emailSequence: EmailSequence): Promise<AxiosResponse<void>> {
    return this.deleteById(emailSequence.id);
  }

  public async deleteById(id: number): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/emailsequences/${id}`);
  }

  public async updateStatus(emailSequence: EmailSequence): Promise<AxiosResponse<StatusResponse>> {
    return this.updateStatusById(emailSequence.id, emailSequence.live);
  }

  public async updateStatusById(id: number, live: boolean): Promise<AxiosResponse<StatusResponse>> {
    return axios.put<StatusResponse>(`/api/emailsequences/${id}/status`, { status: live ? 'live': 'draft'});
  }

  public async listWithStats(
    siteId: number | string,
    pageOptions: PageOptions,
    dateRange: DateRange | null = null
  ): Promise<PaginatedResponse<EmailSequence>> {
    const dateParams: string = dateRange?.getParams() ?? '';
    return axios.get<PaginatedResponseJson<EmailSequenceJson>>(`/api/sites/${siteId}/emailsequences/stats?${pageOptions.query()}&${dateParams}`)
      .then((resp) => PaginatedResponse.fromJSON(resp.data, new EmailSequenceFactory));
  }
}
