import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/RootState";
import Dashboard from "@/api/model/Dashboard";
import Optional from "optional-js";
import DashboardService from "@/api/DashboardService";
import Vue from "vue";
import {DateTime} from "luxon";

export interface DashboardState {
  dashboards: Array<Dashboard>;
  siteId: number | "all" | null;
  from: DateTime;
  to: DateTime;
  prevFrom: DateTime;
  prevTo: DateTime;
  configureMode: boolean;
}

const dashboardService = new DashboardService();

function getDefaultState(): DashboardState {
  return {
    dashboards: [],
    siteId: null,
    from: DateTime.now(),
    to: DateTime.now(),
    prevFrom: DateTime.now(),
    prevTo: DateTime.now(),
    configureMode: false,
  };
}

const state: DashboardState = getDefaultState();

const getters: GetterTree<DashboardState, RootState> = {
  defaultDashboard(state): Dashboard {
    return state.dashboards.find(dashboard => dashboard.default) ?? state.dashboards[0] ?? new Dashboard();
  },
  getDashboard: (state) => (uuid: string): Optional<Dashboard> => Optional.ofNullable(state.dashboards.find(dashboard => dashboard.uuid === uuid)),
  dashboards(state): Array<Dashboard> {
    return state.dashboards;
  },
};

const actions: ActionTree<DashboardState, RootState> = {
  fetchDashboards(context: ActionContext<DashboardState, RootState>) {
    //console.log("fetchDashboards", context);
    return new Promise((resolve, reject) => {
      dashboardService.list()
        .then((dashboards: Dashboard[]) => {
          context.commit('setDashboards', dashboards);
          resolve(dashboards);
        }, error => {
          reject(error);
        });
    });
  },
  resetSitesState (context: ActionContext<DashboardState, RootState>) {
    context.commit('resetState');
  },
  cloneDashboard(context: ActionContext<DashboardState, RootState>, source: Dashboard) {
    const clone = JSON.parse(JSON.stringify(source));
    clone.name = "Clone of " + source.name;
    return new Promise((resolve, reject) => {
      dashboardService.create(clone)
        .then(dashboard => {
          context.commit('addDashboard', dashboard);
          resolve(dashboard);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateDashboard(context: ActionContext<DashboardState, RootState>, source: Dashboard) {
    dashboardService.update(source)
      .then(dashboard => {
        context.commit('updateDashboard', dashboard);
      });
  },
};


type DateRange = { from: DateTime, to: DateTime, prevFrom: DateTime, prevTo: DateTime };
const mutations: MutationTree<DashboardState> = {
  setDashboards(state, dashboards: Array<Dashboard>) {
    //console.log("commit setDashboards", dashboards);
    state.dashboards = dashboards;
  },
  setSiteId(state, currentSiteId: number | "all") {
    state.siteId = currentSiteId;
  },
  setDateRange(state, payload: DateRange) {
    state.from = payload.from;
    state.to = payload.to;
    state.prevFrom = payload.prevFrom;
    state.prevTo = payload.prevTo;
  },
  addDashboard(state, dashboard: Dashboard) {
    //console.log("commit addDashboard", dashboard);
    state.dashboards.push(dashboard);
  },
  updateDashboard(state, newDashboard: Dashboard) {
    //console.log("commit updateDashboard", newDashboard);
    const i = state.dashboards.findIndex(dashboard => dashboard.uuid === newDashboard.uuid);
    Vue.set(state.dashboards, i, newDashboard);
  },
  resetState (state) {
    Object.assign(state, getDefaultState());
  },
  configureToggle(state) {
    state.configureMode = !state.configureMode;
  },
  configureDisable(state) {
    state.configureMode = false;
  },
};

const namespaced = true;

export const dashboard: Module<DashboardState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
